import { ReactNode, FC, MouseEvent, PropsWithChildren } from 'react';

import { CrossScaleSmallEnclosedFalse, IconColor } from 'bloko/blocks/icon';
import IconLink from 'bloko/blocks/icon/IconLink';

import styles from './selected-item.less';

interface SelectedItemsProps {
    children: ReactNode;
    onClear: () => void;
    onClick?: () => void;
}

const MobileSelectedItem: FC<SelectedItemsProps & PropsWithChildren> = ({ children, onClear, onClick }) => {
    return (
        <div className={styles.selectedItem} onClick={onClick}>
            {children}
            <div className={styles.selectedItemClear}>
                <IconLink
                    onClick={(event: MouseEvent) => {
                        onClear();
                        event.stopPropagation();
                    }}
                >
                    <CrossScaleSmallEnclosedFalse highlighted={IconColor.Blue60} initial={IconColor.Gray50} />
                </IconLink>
            </div>
        </div>
    );
};

export default MobileSelectedItem;
