import { useEffect, useRef, useState } from 'react';

import Button, { ButtonAppearance, ButtonKind, ButtonType } from 'bloko/blocks/button';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notices, { MarkAsViewedEvent } from 'Modules/Notices';
import Infotip, { InfoPlacement } from 'lux/components/Infotip';
import MagritteInfotip from 'lux/components/Infotip/MagritteInfotip';
import translation from 'lux/components/translation';
import useMagritte from 'lux/hooks/useMagritte';
import { NovaFilterKey } from 'lux/models/novaFilters';
import { VerifiedSkillCategory } from 'lux/models/resume/resumeVerifiedSkills.types';
import { useSelector } from 'lux/modules/useSelector';

export const VERIFIED_FILTER_TIP = 'verifiedFilterTip';

export interface VerifiedFilterInfoTipProps {
    children: JSX.Element;
    category: string;
}

const TrlKeys = {
    text: 'clusters.verifiedTip',
    gotIt: 'clusters.gotIt',
};

const VerifiedFilterInfoTip: TranslatedComponent<VerifiedFilterInfoTipProps> = ({ children, category, trls }) => {
    const isMagritte = useMagritte();

    const infoTip = useSelector(({ infoTip }) => infoTip);
    const searchCluster = useSelector(({ searchClusters }) => searchClusters);
    const isFiltersOnTheLeft = useSelector((state) => state.resumeSearchResult.settings.filtersOnTheLeft);
    const verifiedSkills = Object.keys(searchCluster[NovaFilterKey.VerifiedSkills].groups);
    const verifiedLanguages = Object.keys(
        searchCluster[NovaFilterKey.VerifiedLanguages] ? searchCluster[NovaFilterKey.VerifiedLanguages].groups : []
    );
    const initRef = useRef(null);
    const [host, setHost] = useState(null);
    useEffect(() => {
        setHost(initRef.current);
    }, []);

    const verifiedCategory = (() => {
        if (verifiedSkills.length > 0 && verifiedLanguages.length > 0) {
            return VerifiedSkillCategory.Skill;
        } else if (verifiedSkills.length > 0 && verifiedLanguages.length === 0) {
            return VerifiedSkillCategory.Skill;
        } else if (verifiedSkills.length === 0 && verifiedLanguages.length > 0) {
            return VerifiedSkillCategory.Lang;
        }
        return null;
    })();

    const [isTipVisible, setIsTipVisible] = useState(
        infoTip.name === VERIFIED_FILTER_TIP && verifiedCategory === category
    );

    const onClose = () => {
        setIsTipVisible(false);
        Notices.markAsViewed(VERIFIED_FILTER_TIP);
    };

    return (
        <div ref={initRef} style={{ marginBottom: '4px' }}>
            {initRef.current &&
                (isMagritte ? (
                    <MagritteInfotip
                        name={VERIFIED_FILTER_TIP}
                        render={({ activatorRef }) => <span ref={activatorRef}>{children}</span>}
                        bannerStyle="contrast"
                        placement={`${isFiltersOnTheLeft?.value ? 'right' : 'left'}-top`}
                        showClose
                        closeByClickOutside={false}
                        showBannerOnMobile={false}
                        visible={isTipVisible}
                        action={trls[TrlKeys.gotIt]}
                        actionProps={{ onClick: onClose }}
                        markAsViewedEvent={MarkAsViewedEvent.OnHide}
                        onClose={onClose}
                    >
                        {trls[TrlKeys.text]}
                    </MagritteInfotip>
                ) : (
                    <Infotip
                        show={isTipVisible}
                        name={VERIFIED_FILTER_TIP}
                        onClose={onClose}
                        placement={isFiltersOnTheLeft?.value ? InfoPlacement.BottomStart : InfoPlacement.LeftStart}
                        markAsViewedEvent={MarkAsViewedEvent.OnHide}
                        closeByClickOutside={false}
                        host={host}
                        render={() => (
                            <>
                                <p>{trls[TrlKeys.text]}</p>
                                <VSpacing base={2} />
                                <Button
                                    kind={ButtonKind.Inversed}
                                    type={ButtonType.Button}
                                    appearance={ButtonAppearance.Outlined}
                                    onClick={onClose}
                                >
                                    {trls[TrlKeys.gotIt]}
                                </Button>
                            </>
                        )}
                    >
                        {children}
                    </Infotip>
                ))}
        </div>
    );
};

export default translation(VerifiedFilterInfoTip);
