import { forwardRef, PropsWithChildren } from 'react';
import classnames from 'classnames';

const MAX_GROUPS_NOT_SCROLLING_LEN = 10;

interface FilterListProps {
    expanded?: boolean;
    len?: number;
    scrollable?: boolean;
}

const FilterList = forwardRef<HTMLUListElement, PropsWithChildren<FilterListProps>>(
    ({ children, len = 0, expanded = false, scrollable = false }, ref) => (
        <ul
            className={classnames('novafilters-list', {
                'novafilters-list_scrolling': scrollable || (expanded && len > MAX_GROUPS_NOT_SCROLLING_LEN),
            })}
            ref={ref}
        >
            {children}
        </ul>
    )
);

export default FilterList;
