import { ReactElement } from 'react';

import Checkbox from 'bloko/blocks/checkbox';

import ListItem from 'lux/components/NovaFilters/components/ListItem';
import { NovaFilterKey, NovaFilterRangeConvertedToStrings, NovaFilterGender } from 'lux/models/novaFilters';

type AcceptableValueTypes = NovaFilterRangeConvertedToStrings | NovaFilterGender;

interface OnlyWithCheckboxProps<T> {
    values: T;
    onChange: (data: T, forceUpdate?: boolean) => void;
    name: typeof NovaFilterKey.Salary | typeof NovaFilterKey.Age | typeof NovaFilterKey.Gender;
    title: string;
}

const OnlyWithCheckbox = <T extends AcceptableValueTypes>({
    values,
    onChange,
    name,
    title,
}: OnlyWithCheckboxProps<T>): ReactElement => {
    return (
        <ListItem>
            <Checkbox
                onChange={() => {
                    onChange({ ...values, onlyWith: !values.onlyWith }, true);
                }}
                checked={values.onlyWith}
                data-qa={`serp__novafilter-only_with_${name}`}
                labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
            >
                <span data-qa="serp__novafilter-title">{title}</span>
            </Checkbox>
        </ListItem>
    );
};

export default OnlyWithCheckbox;
