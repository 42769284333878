import { useState, useEffect, FC } from 'react';

import InputText from 'bloko/blocks/inputText';
import Suggest from 'bloko/blocks/suggest';
import { DataProvider, DataProviderItem } from 'bloko/blocks/suggest/types';
import TagList from 'bloko/blocks/tagList';
import Tag from 'bloko/blocks/tagList/tag';

import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';

const SUGGEST_EMPTY_ITEM: DataProviderItem = { id: undefined, text: '' };
export interface NovaFilterWithSuggestProps {
    title: string;
    selectedValues: Required<DataProviderItem>[];
    name: string;
    onChange: (values: Required<DataProviderItem>[]) => void;
    dataProvider: DataProvider<DataProviderItem>;
    placeholder?: string;
}

const NovaFilterWithSuggest: FC<NovaFilterWithSuggestProps> = ({
    title,
    selectedValues,
    name,
    onChange,
    dataProvider,
    placeholder,
}) => {
    const [suggestValue, setSuggestValue] = useState<DataProviderItem>(SUGGEST_EMPTY_ITEM);
    const [selectedItems, setSelectedItems] = useState(selectedValues);

    useEffect(() => {
        setSelectedItems(selectedValues);
    }, [selectedValues]);

    const onSuggestChange = (item: DataProviderItem) => {
        setSuggestValue(item);
        if (!item || !item.id) {
            return;
        }
        if (!selectedItems.map(({ id }) => id).includes(item.id)) {
            const newItems = selectedItems.slice();
            newItems.push(item as Required<DataProviderItem>);
            setSuggestValue(SUGGEST_EMPTY_ITEM);
            setSelectedItems(newItems);
            onChange(newItems);
        }
    };

    const onSuggestRemove = (itemId: string) => {
        const newItems = selectedItems.filter(({ id }) => id !== itemId);
        setSelectedItems(newItems);
        onChange(newItems);
    };

    return (
        <NovaFilterWrapper title={title}>
            <Suggest
                dataProvider={dataProvider}
                value={suggestValue}
                onChange={onSuggestChange}
                selectOnBlur={false}
                limit={5}
                data-qa={`serp__novafilter-item-${name}`}
            >
                <InputText placeholder={placeholder} data-qa={`novafilter-item-input__${name}`} />
            </Suggest>
            <TagList
                removable
                items={selectedItems.map(({ id, text }) => (
                    <Tag key={id} onRemove={() => onSuggestRemove(id)}>
                        {text}
                    </Tag>
                ))}
            />
        </NovaFilterWrapper>
    );
};

export default NovaFilterWithSuggest;
