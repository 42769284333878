import { useEffect, useState } from 'react';
import { RouterLocation } from 'connected-react-router';

import urlParser from 'bloko/common/urlParser';

import SearchType from 'lux/components/NovaFilters/SearchType';
import { useSearchParams } from 'lux/components/NovaFilters/actions/sendFilterForm/getSearchParams/useSearchParams';
import { VacancySearchParams } from 'lux/components/NovaFilters/actions/sendFilterForm/getSearchParams/vacancy';
import { VacancyMapSearchParams } from 'lux/components/NovaFilters/actions/sendFilterForm/getSearchParams/vacancyMap';
import { NovaFilterKey } from 'lux/models/novaFilters';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';

import { ItemMap } from 'lux/components/NovaFilters/components/NovaFilterWithAdditionalList/AdditionalList';

type UseAdditionalList = (filterName: NovaFilterKey) => {
    showAdditionalList: boolean;
    toggleShowAdditionalList: () => void;
    isLoading: boolean;
    list: ItemMap | null;
};

const URL = '/shards/search_additional_list';

declare global {
    interface FetcherGetApi {
        [k: `${typeof URL}${string}`]: {
            queryParams: never;
            body: never;
            response: ItemMap;
        };
    }
}

const fetchAdditionalList = async (
    searchType: SearchType,
    location: RouterLocation<unknown>,
    searchParams: VacancySearchParams | VacancyMapSearchParams,
    filterName: NovaFilterKey
) => {
    const query = urlParser.stringify(searchParams as never);
    const urlObj = urlParser(`${location.pathname}?${query}`);
    urlObj.params = { ...urlObj.params, searchType, filterName };

    return fetcher.get(`${URL}${urlObj.search}`);
};

// Additional list is fetched on first 'show all' button click
// If other filters are applied, additional list is not valid anymore and we collapse them
// It will be fetched again on next 'show all' click
export const useAdditionalList: UseAdditionalList = (filterName) => {
    const [list, setList] = useState<ItemMap | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showAdditionalList, setShowAdditionalList] = useState(false);

    const searchType = useSelector((state) => state.searchClustersSettings.type);
    const location = useSelector((state) => state.router.location);
    const searchParams = useSearchParams();

    const searchClustersLastAppliedFilter = useSelector(
        ({ searchClustersLastAppliedFilter }) => searchClustersLastAppliedFilter
    );

    useEffect(() => {
        // If any filter other than this filter changes, we collapse list and fetch again on next 'show all' click
        if (searchClustersLastAppliedFilter?.filterName === filterName) {
            return;
        }

        setList(null);
        setShowAdditionalList(false);
    }, [filterName, searchClustersLastAppliedFilter]);

    const toggleShowAdditionalList = async () => {
        if (isLoading) {
            return;
        }

        if (list) {
            setShowAdditionalList((prevState) => !prevState);
            return;
        }

        setIsLoading(true);

        try {
            const updatedList = await fetchAdditionalList(
                searchType,
                location,
                searchParams as VacancySearchParams | VacancyMapSearchParams,
                filterName
            );
            setList(updatedList);
            setShowAdditionalList(true);
        } catch (error) {
            setList(null);
            setShowAdditionalList(false);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return { showAdditionalList, toggleShowAdditionalList, isLoading, list };
};
