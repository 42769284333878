import { useMemo, useCallback, useState } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';

import MagritteNovaControl from 'lux/components/NovaFilters/components/Magritte/NovaControl';
import NovaControl from 'lux/components/NovaFilters/components/NovaControl';
import NovaFilterChildTree from 'lux/components/NovaFilters/components/NovaTree/NovaFilterChildTree';
import NovaFilterParentTree from 'lux/components/NovaFilters/components/NovaTree/NovaFilterParentTree';
import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import translation from 'lux/components/translation';
import { NovaFilterKey } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

import IndustryMobile from 'lux/components/NovaFilters/vacancies/IndustryAndSubIndustry/IndustryMobile';

const TrlKeys = {
    industry: 'resumeBuilder.industry',
    subIndustry: 'clusters.subindustry',
};

const IndustryAndSubIndustry: TranslatedComponent<{ isMagritte?: boolean }> = ({ isMagritte, trls }) => {
    // collection data
    const filterUpdate = useNovaFilterUpdate();
    const industryTree = useSelector((state) => state.industriesTree);
    const industryTreeTreeCollection = useMemo(() => fromTree(industryTree), [industryTree]);

    // parent/children filter
    const industry = useSelector((state) => state.searchClusters[NovaFilterKey.Industry]);
    const subIndustry = useSelector((state) => state.searchClusters[NovaFilterKey.SubIndustry]);
    const order = useSelector((state) => state.searchClustersOrder?.[NovaFilterKey.Industry]) || [];
    const childrenOrder = useSelector((state) => state.searchClustersOrder?.[NovaFilterKey.SubIndustry]) || [];

    // local state and callback
    const selectedValues = industry?.selectedValues || [];
    const [values, setValues] = useState(selectedValues);
    const setCheckedValuesWithOnChange = useCallback(
        (newValues: string[]) => {
            setValues(newValues);
            filterUpdate(newValues, NovaFilterKey.Industry);
        },
        [filterUpdate]
    );

    const WrapperComponent = isMagritte ? MagritteNovaControl : NovaControl;

    return (
        <WrapperComponent
            mobileView={
                <IndustryMobile
                    title={trls[TrlKeys.industry]}
                    initialValues={selectedValues}
                    collection={industryTreeTreeCollection}
                    isMagritte={isMagritte}
                />
            }
        >
            <NovaFilterParentTree
                title={trls[TrlKeys.industry]}
                setCheckedValuesWithOnChange={setCheckedValuesWithOnChange}
                treeCollection={industryTreeTreeCollection}
                selectedValues={values}
                parentFilter={industry}
                childrenFilter={subIndustry}
                order={order}
                isMagritte={isMagritte}
            />
            <NovaFilterChildTree
                title={trls[TrlKeys.subIndustry]}
                selectedValues={values}
                setCheckedValuesWithOnChange={setCheckedValuesWithOnChange}
                parentFilter={industry}
                childrenFilter={subIndustry}
                parentField="industryId"
                childrenOrder={childrenOrder}
                isMagritte={isMagritte}
            />
        </WrapperComponent>
    );
};

export default translation(IndustryAndSubIndustry);
