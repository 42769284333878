import { FC, RefObject, useRef } from 'react';

import { AdditionalFiltersOrderKey } from 'lux/hooks/useNovaFiltersOrderForResume';
import { NovaFilterKey } from 'lux/models/novaFilters';
import { Criteria } from 'lux/models/resumeSearchCriteria';
import { useSelector } from 'lux/modules/useSelector';

import AdditionalNovaFilters from 'lux/components/NovaFilters/AdditionalNovaFilters';
import CounterButton from 'lux/components/NovaFilters/CounterButton';
import NovaFilterReset from 'lux/components/NovaFilters/Reset';
import SearchLanguageLink from 'lux/components/NovaFilters/SearchLanguageLink';
import SearchType from 'lux/components/NovaFilters/SearchType';
import FilterTip from 'lux/components/NovaFilters/components/FilterTip';
import FormHeader from 'lux/components/NovaFilters/components/FormHeader';
import NovaFilter from 'lux/components/NovaFilters/components/NovaFilter';
import SortingGroup from 'lux/components/NovaFilters/components/SortingGroup';
import useNovaFilterMap from 'lux/components/NovaFilters/hooks/useNovaFilterMap';
import useSendFilterForm from 'lux/components/NovaFilters/hooks/useSendFilterForm';
import TotalFilters from 'lux/components/NovaFilters/resumes/Total';

interface Props {
    criteria: Criteria;
    filtersOrder: NovaFilterKey[];
    additionalFiltersOrder: AdditionalFiltersOrderKey[];
    withCounter: boolean;
    withSorting: boolean;
    isMagritte?: boolean;
    isIndexAnonSearchRedirectDisabled?: boolean;
}

const customSortFiltersVacancies = [NovaFilterKey.Metro];

const NovaFilters: FC<Props> = ({
    criteria,
    filtersOrder,
    additionalFiltersOrder = [],
    withCounter = false,
    withSorting = false,
    isMagritte = false,
    isIndexAnonSearchRedirectDisabled = false,
}) => {
    const eventElRef = useRef<EventTarget | null>(null);
    const formRef = useRef(null);
    const filtersRefs = useRef<Partial<Record<NovaFilterKey, HTMLDivElement>>>({});
    const sendFilterForm = useSendFilterForm();
    const clusters = useSelector((state) => state.searchClusters);
    const searchType = useSelector((state) => state.searchClustersSettings.type);

    const customSortFilters =
        searchType === SearchType.Vacancy || searchType === SearchType.VacancyMap ? customSortFiltersVacancies : [];
    const filterMap = useNovaFilterMap();

    const setFilterRef = (ref: HTMLDivElement | null, filter: NovaFilterKey) => {
        if (ref && filtersRefs.current) {
            filtersRefs.current[filter] = ref;
        }
    };

    const setEventRef = (element: EventTarget) => {
        eventElRef.current = element;
    };

    if (!clusters) {
        return null;
    }
    return (
        <>
            {!isMagritte && searchType === SearchType.Vacancy && <FormHeader />}
            {withSorting && <SortingGroup isMagritte={isMagritte} />}
            <aside
                ref={formRef}
                className="novafilters"
                data-qa="serp__criterias"
                onClick={(event) => {
                    setEventRef(event.target);
                }}
            >
                <FilterTip
                    formRef={formRef}
                    eventElRef={eventElRef as RefObject<HTMLDivElement>}
                    filtersRefs={filtersRefs}
                    setEventRef={setEventRef}
                    isMagritte={isMagritte}
                />
                {searchType === SearchType.Resume && <TotalFilters />}
                {filtersOrder.map((filter) => {
                    if (!clusters[filter] && !customSortFilters.includes(filter)) {
                        return null;
                    }

                    const Component: FC<{ name: string; isMagritte?: boolean }> =
                        (filterMap as never)[filter] || NovaFilter;
                    return (
                        <div key={filter} ref={(ref) => setFilterRef(ref, filter)}>
                            <Component name={filter} isMagritte={isMagritte} />
                        </div>
                    );
                })}
                <AdditionalNovaFilters criteria={criteria} additionalFiltersOrder={additionalFiltersOrder} />
                {!isIndexAnonSearchRedirectDisabled && <NovaFilterReset isMagritte={isMagritte} />}
                <SearchLanguageLink searchType={searchType} isMagritte={isMagritte} />
                {!isMagritte && withCounter && (
                    <CounterButton
                        submitFilters={sendFilterForm}
                        isIndexAnonSearchRedirectDisabled={isIndexAnonSearchRedirectDisabled}
                    />
                )}
            </aside>
        </>
    );
};

export default NovaFilters;
