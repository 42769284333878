import { useMemo } from 'react';

import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import { DataProviderItem } from 'bloko/blocks/suggest/types';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';

import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import translation from 'lux/components/translation';
import useFetchAreaTree from 'lux/hooks/useFetchAreaTree';
import { NovaFilterKey } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

import NovaFilterWithSuggest from 'lux/components/NovaFilters/components/Bloko/NovaFilterWithSuggest';

interface RegionSuggestProps {
    title: string;
    name: typeof NovaFilterKey.Citizenship | typeof NovaFilterKey.WorkTicket;
}

const TrlKeys = {
    country: 'employer.resumesSearch.region.country',
};

const REMOTE = '/autosuggest/multiprefix/v2?q=%QUERY%&d=countries_';
const WILDCARD = '%QUERY%';

const RegionSuggest: TranslatedComponent<RegionSuggestProps> = ({ trls, title, name }) => {
    useFetchAreaTree();
    const lang = useSelector((state) => state.langs[0]);
    const dataProvider = useMemo(() => createRemoteDataProvider(REMOTE + lang, WILDCARD), [lang]);
    const areaTree = useSelector((state) => state.areaTree);
    const areaTreeCollection = fromTree(areaTree);

    const filterUpdate = useNovaFilterUpdate();
    const selectedValues = useSelector((state) => state.searchClusters[name].selectedValues);
    const enrichmentSelectedValues: Required<DataProviderItem>[] = selectedValues.map((id) => ({
        id,
        text: areaTreeCollection.getModel(id)?.text || '',
    }));

    return (
        <NovaFilterWithSuggest
            title={title}
            selectedValues={enrichmentSelectedValues}
            name={name}
            onChange={(values) => {
                filterUpdate(
                    values.map(({ id }) => `${id}`),
                    name
                );
            }}
            dataProvider={dataProvider}
            placeholder={trls[TrlKeys.country]}
        />
    );
};

export default translation(RegionSuggest);
