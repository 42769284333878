import { FC, ReactNode, PropsWithChildren } from 'react';

import { useSelector } from 'lux/modules/useSelector';

import styles from './nova-control.less';

interface NovaControlProps {
    mobileView?: ReactNode;
}

const NovaControl: FC<NovaControlProps & PropsWithChildren> = ({ mobileView, children }) => {
    const searchMobileControls = useSelector((state) => state.searchMobileControls);
    return (
        <>
            <div className={searchMobileControls ? styles.novaControl : ''}>{children}</div>
            {searchMobileControls && mobileView && <div className={styles.novaControlXs}>{mobileView}</div>}
        </>
    );
};

export default NovaControl;
