import { useCallback, useState } from 'react';

import debounce from 'bloko/common/debounce';

import { MetroType, NovaFilterMetroGroup } from 'lux/models/novaFilters';

import useMetroDataProvider, {
    MetroDataProviderResponse,
} from 'lux/components/NovaFilters/vacancies/Metro/hooks/useMetroDataProvider';

type UseSearchMetroHook = () => {
    isSearchMetroEnabled: boolean;
    searchMetroList: NovaFilterMetroGroup[];
    searchMetroDebounced: (searchText: string, onSuccess?: (isSearchMetroEnabled: boolean) => void) => void;
};

const useSearchMetro: UseSearchMetroHook = () => {
    const [searchMetroList, setSearchMetroList] = useState<NovaFilterMetroGroup[]>([]);
    const [isSearchMetroEnabled, setIsSearchMetroEnabled] = useState(false);

    const dataProvider = useMetroDataProvider();

    const searchMetroDebounced = useCallback(
        debounce(async (searchText: string, onSuccess?: (isSearchMetroEnabled: boolean) => void) => {
            let results: MetroDataProviderResponse;
            try {
                results = await dataProvider(searchText.trim().toUpperCase());
            } catch (error) {
                console.error(error);
                return;
            }

            if (!results.items) {
                return;
            }

            const list = results.items.map(({ text, id, color }) => {
                return {
                    id,
                    color,
                    title: text,
                    // TODO: add «type» property to the response
                    type: id.indexOf('.') === -1 ? MetroType.Line : MetroType.Station,
                    count: 0,
                };
            });

            setSearchMetroList(list);
            setIsSearchMetroEnabled(searchText.length > 0);

            onSuccess && onSuccess(isSearchMetroEnabled);
        }, 400),
        []
    );

    return { isSearchMetroEnabled, searchMetroList, searchMetroDebounced };
};

export default useSearchMetro;
