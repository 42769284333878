import { useCallback, useMemo } from 'react';

import {
    Text,
    Cell,
    CellText,
    Radio,
    Select,
    SelectOption,
    SelectOptionProps,
    VSpacing,
    HSpacing,
} from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { Language } from 'lux/models/resumeLanguage';
import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import { useSelector } from 'lux/modules/useSelector';
import { DEFAULT_LANGUAGES } from 'lux/utils/sortForLanguages';

const TrlKeys = {
    placeholder: 'search.filter.language.placeholder',
    popularDelimiter: 'search.filter.language.delimiter.popular',
};

const getDividedLanguageOptions = (languages: Language[], popularDelimiter: string): Array<SelectOption<Language>> => {
    const popularAcc: Array<SelectOption<Language>> = [];
    const otherAcc: Array<SelectOption<Language>> = [];

    let hasPopularDelimiter = false;
    let currentLetterDelimiter = '';
    // languages - an array sorted in lexicographic order (by `title` field) in asc order
    languages.forEach(({ code, title }) => {
        if (DEFAULT_LANGUAGES.includes(code)) {
            if (!hasPopularDelimiter) {
                hasPopularDelimiter = true;
                popularAcc.push({ label: popularDelimiter, value: `unique-popular`, type: 'delimiter' });
            }
            popularAcc.push({ label: title, value: code });
            return;
        }

        const firstLetter = title.charAt(0).toUpperCase();
        if (currentLetterDelimiter !== firstLetter) {
            currentLetterDelimiter = firstLetter;
            otherAcc.push({ label: firstLetter, value: `unique-${firstLetter}`, type: 'delimiter' });
        }
        otherAcc.push({ label: title, value: code });
    });

    return [...popularAcc, ...otherAcc];
};

type Props = {
    code: string;
    onChange: (code: string) => void;
    selectedLanguages: string[];
    dataQa: string;
};

const LanguageSelect: TranslatedComponent<Props> = ({ code, onChange, selectedLanguages, dataQa, trls }) => {
    const languages = useSelector((state) => state.languages.language);

    const options: Array<SelectOption<Language>> = useMemo(
        () => getDividedLanguageOptions(languages, trls[TrlKeys.popularDelimiter]),
        [languages, trls]
    );

    const renderOption = useCallback(
        ({ label, value, type }: SelectOptionProps<Language>) => {
            if (type === 'delimiter') {
                return (
                    <>
                        <VSpacing default={8} />
                        <HSpacing default={16} />
                        <Text Element="span" typography="label-2-regular" style="secondary">
                            {label}
                        </Text>
                    </>
                );
            }
            const isSelected = value === code;
            return (
                <Cell
                    left={<Radio checked={isSelected} readOnly />}
                    onChange={() => onChange(value)}
                    disabled={!isSelected && selectedLanguages.includes(value)}
                >
                    <CellText data-qa={`${dataQa}-${value}`}>{label}</CellText>
                </Cell>
            );
        },
        [code, dataQa, onChange, selectedLanguages]
    );

    return (
        <div style={{ position: 'relative' }} data-qa={dataQa}>
            <Select
                name={CriteriaKey.Language}
                placeholder={trls[TrlKeys.placeholder]}
                options={options}
                renderItem={renderOption}
                renderItemForDesktop={renderOption}
                value={code}
                onChange={onChange}
            />
        </div>
    );
};

export default translation(LanguageSelect);
