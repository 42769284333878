import { FC } from 'react';

import useMagritte from 'lux/hooks/useMagritte';

import NovaFilterBloko from 'lux/components/NovaFilters/components/Bloko/NovaFilter';
import NovaFilterMagritte from 'lux/components/NovaFilters/components/Magritte/NovaFilter';
import { FilterContent } from 'lux/components/NovaFilters/components/NovaFilterContent';

export interface NovaFilterProps extends FilterContent {
    title: string;
}

const NovaFilter: FC<NovaFilterProps> = (props) => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? NovaFilterMagritte : NovaFilterBloko;

    return <Component {...props} />;
};

export default NovaFilter;
