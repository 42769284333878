import { useCallback, useMemo, useState } from 'react';

import { Checkbox, Link } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { AdditionalDefault } from 'bloko/common/tree/types';

import FilterList from 'lux/components/NovaFilters/components/Magritte/FilterList';
import LinkMore from 'lux/components/NovaFilters/components/Magritte/LinkMore';
import MobileTreeSelector from 'lux/components/NovaFilters/components/Magritte/MobileTreeSelector';
import MagritteNovaFilterItemWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import NovaFilterWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import useNovaFiltersGroups from 'lux/components/NovaFilters/hooks/useNovaFiltersGroups';
import translation from 'lux/components/translation';
import { NovaFilterGroup, NovaFilterKey, OTHER_ROLES_ID } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

const sortOther = (a: NovaFilterGroup<string>, b: NovaFilterGroup<string>): number => {
    if (a.id === OTHER_ROLES_ID) {
        return 1;
    }
    if (b.id === OTHER_ROLES_ID) {
        return -1;
    }
    return 0;
};

type ProfessionalRolesMobileResumeProps = {
    initialValues: string[];
    title: string;
    collection: TreeCollection<AdditionalDefault>;
    groups: Record<string, NovaFilterGroup<string>>;
    isMagritte?: boolean;
};

const TrlKeys = {
    add: 'novaFilters.professionalRoles.add',
    searchParent: 'novaFilters.professionalRoles.search.parent',
    searchChild: 'novaFilters.professionalRoles.search.child',
    chooseMore: 'clusters.chooseMore',
};

const ProfessionalRolesMobileResume: TranslatedComponent<ProfessionalRolesMobileResumeProps> = ({
    initialValues,
    title,
    trls,
    collection,
    groups,
}) => {
    // store's data
    const filterUpdate = useNovaFilterUpdate();
    const [showModal, setShowModal] = useState<boolean>(false);

    const onChange = useCallback(
        (id: string) => {
            const index = initialValues.indexOf(id);
            const newSelectedValues = [...initialValues];
            if (index === -1) {
                newSelectedValues.push(id);
            } else {
                newSelectedValues.splice(index, 1);
            }
            filterUpdate(newSelectedValues, NovaFilterKey.ProfessionalRole);
        },
        [filterUpdate, initialValues]
    );

    // filtred items to show in list
    const orders = useSelector((state) => state.searchClustersOrder?.[NovaFilterKey.ProfessionalRole]) || [];
    const { items, breakpoint } = useNovaFiltersGroups(groups, initialValues.length, orders);

    const filteredItems = useMemo(() => {
        const filteredItems = items.slice(0, breakpoint);
        if (!initialValues.includes(OTHER_ROLES_ID)) {
            filteredItems.sort(sortOther);
        }
        return filteredItems;
    }, [breakpoint, initialValues, items]);

    return (
        <>
            <NovaFilterWrapper title={title}>
                <FilterList>
                    {filteredItems.map(({ title, id }) => {
                        const group = groups[id];
                        return (
                            <MagritteNovaFilterItemWrapper
                                key={id}
                                left={
                                    <Checkbox
                                        value={id}
                                        onChange={({ target }) => {
                                            onChange(target.value);
                                        }}
                                        checked={initialValues.includes(id)}
                                        disabled={!group}
                                    />
                                }
                                title={title}
                                count={group.count}
                                disabled={!group}
                            />
                        );
                    })}
                </FilterList>
                <LinkMore>
                    <Link
                        Element="button"
                        onClick={() => setShowModal(true)}
                        data-qa="novafilters-mobile-add-professional-roles"
                    >
                        {trls[TrlKeys.chooseMore]}
                    </Link>
                </LinkMore>
            </NovaFilterWrapper>
            <MobileTreeSelector
                name={NovaFilterKey.ProfessionalRole}
                collection={collection}
                initialValues={initialValues}
                showModal={showModal}
                setShowModal={setShowModal}
            />
        </>
    );
};

export default translation(ProfessionalRolesMobileResume);
