import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaControl from 'lux/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterContent from 'lux/components/NovaFilters/components/Magritte/NovaFilterContent';
import MagritteNovaFilterWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import NovaControl from 'lux/components/NovaFilters/components/NovaControl';
import NovaFilterContent from 'lux/components/NovaFilters/components/NovaFilterContent';
import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';
import translation from 'lux/components/translation';
import { NovaFilterKey } from 'lux/models/novaFilters';

import NeighboursAreaMobile from 'lux/components/NovaFilters/vacancies/Neighbours/NeighboursMobile';

const TrlKeys = {
    title: 'clusters.neighbours',
};
const Neighbours: TranslatedComponent<{ isMagritte?: boolean }> = ({ isMagritte, trls }) => {
    if (isMagritte) {
        return (
            <MagritteNovaControl mobileView={<NeighboursAreaMobile title={trls[TrlKeys.title]} isMagritte />}>
                <MagritteNovaFilterWrapper title={trls[TrlKeys.title]}>
                    <MagritteNovaFilterContent name={NovaFilterKey.Neighbours} />
                </MagritteNovaFilterWrapper>
            </MagritteNovaControl>
        );
    }

    return (
        <NovaControl mobileView={<NeighboursAreaMobile title={trls[TrlKeys.title]} />}>
            <NovaFilterWrapper title={trls[TrlKeys.title]}>
                <NovaFilterContent name={NovaFilterKey.Neighbours} />
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(Neighbours);
