import { Fragment } from 'react';

import { Badge, HSpacing, Link } from '@hh.ru/magritte-ui';
import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NoIndex from 'lux/components/NoIndex';
import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';
import translation from 'lux/components/translation';
import useMagritte from 'lux/hooks/useMagritte';
import paths from 'lux/modules/routePaths';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    title: 'resumesSearch.novaFilters.conditions',
    add: 'resumesSearch.novaFilters.add',
    edit: 'resumesSearch.novaFilters.edit',
};

const Total: TranslatedComponent = ({ trls }) => {
    const isMagritte = useMagritte();

    const totalUsedFilters = useSelector((state) => state.resumeSearchResult.totalUsedFilters ?? 0);
    const currentParams = useSelector((state) => state.resumeSearchResult.queries?.currentParams ?? '');

    return (
        <NovaFilterWrapper
            title={
                <Fragment>
                    {trls[TrlKeys.title]}
                    {totalUsedFilters > 0 &&
                        (isMagritte ? (
                            <>
                                <HSpacing default={6} />
                                <Badge style="neutral">{`${totalUsedFilters}`}</Badge>
                            </>
                        ) : (
                            <span className="supernova-navi-counter supernova-navi-counter_clear">
                                {totalUsedFilters}
                            </span>
                        ))}
                </Fragment>
            }
        >
            <NoIndex>
                {isMagritte ? (
                    <Link
                        Element={SpaLink}
                        to={`${paths.resumeSearch}/advanced?no_default_area&${currentParams}`}
                        data-qa="resume-serp__change-request"
                        rel="nofollow"
                    >
                        {totalUsedFilters > 0 ? trls[TrlKeys.edit] : trls[TrlKeys.add]}
                    </Link>
                ) : (
                    <BlokoLink
                        disableVisited
                        Element={SpaLink}
                        to={`${paths.resumeSearch}/advanced?no_default_area&${currentParams}`}
                        data-qa="resume-serp__change-request"
                        rel="nofollow"
                    >
                        {totalUsedFilters > 0 ? trls[TrlKeys.edit] : trls[TrlKeys.add]}
                    </BlokoLink>
                )}
            </NoIndex>
        </NovaFilterWrapper>
    );
};

export default translation(Total);
