import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilter from 'lux/components/NovaFilters/components/NovaFilter';
import translation from 'lux/components/translation';
import { NovaFilterKey } from 'lux/models/novaFilters';

const TrlKeys = {
    title: 'clusters.driverLicenseTypes',
};

const DriverLicenseTypes: TranslatedComponent<{ active: boolean }> = ({ trls, active = false }) => {
    if (!active) {
        return <>{trls[TrlKeys.title]}</>;
    }
    return <NovaFilter title={trls[TrlKeys.title]} name={NovaFilterKey.DriverLicenseTypes} />;
};

export default translation(DriverLicenseTypes);
