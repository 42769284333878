import { forwardRef, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { VSpacing } from '@hh.ru/magritte-ui';

import styles from './styles.less';

const MAX_GROUPS_NOT_SCROLLING_LEN = 10;

interface FilterListProps {
    expanded?: boolean;
    len?: number;
    scrollable?: boolean;
}

const FilterList = forwardRef<HTMLUListElement, PropsWithChildren<FilterListProps>>(
    ({ children, len = 0, expanded = false, scrollable = false }, ref) => {
        const showScrolling = scrollable || (expanded && len > MAX_GROUPS_NOT_SCROLLING_LEN);
        return (
            <div className={styles.listContainer}>
                <ul
                    className={classnames({
                        [styles.listScrolling]: showScrolling,
                    })}
                    ref={ref}
                >
                    {children}
                    {showScrolling && <VSpacing default={8} />}
                </ul>
            </div>
        );
    }
);

export default FilterList;
