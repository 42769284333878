import { FormItem } from 'bloko/blocks/form';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';
import sortForLanguages from 'lux/utils/sortForLanguages';

const DEFAULT_LEVEL = 'a1';

interface ResumeLanguageFilterSelectedProps {
    value: string[];
    onChange: (value: string[]) => void;
    preTitleDataQa: string;
    isVertical?: boolean;
}

const TrlKeys = {
    add: 'employer.resumesSearch.addLang',
    addOneMore: 'vacancyresponse.filters.language.OneMoreLanguage',
};

const ResumeLanguageFilterSelected: TranslatedComponent<ResumeLanguageFilterSelectedProps> = ({
    trls,
    value,
    onChange,
    preTitleDataQa,
    isVertical = true,
}) => {
    const collection = useSelector((state) => sortForLanguages(state.languages.language)) || [];
    const selectedLanguages = value.map((language) => language.split('.')[0]);

    if (selectedLanguages.length >= collection.length) {
        return null;
    }

    const content = (
        <Link
            appearance={LinkAppearance.Pseudo}
            data-qa={`${preTitleDataQa}-add-language`}
            onClick={() =>
                onChange([
                    ...value,
                    `${collection.find(({ code }) => !selectedLanguages.includes(code))?.code || ''}.${DEFAULT_LEVEL}`,
                ])
            }
        >
            {selectedLanguages.length > 0 ? trls[TrlKeys.addOneMore] : trls[TrlKeys.add]}
        </Link>
    );

    if (isVertical) {
        return (
            <>
                <VSpacing base={2} />
                {content}
            </>
        );
    }

    return <FormItem baseline>{content}</FormItem>;
};

export default translation(ResumeLanguageFilterSelected);
