import { RefObject } from 'react';

import { ControlGroupItem } from 'bloko/blocks/controlGroup';
import Tip, { TipTheme } from 'bloko/blocks/drop/Tip';
import InputText from 'bloko/blocks/inputText';
import { KeyCode } from 'bloko/common/constants/keyboard';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { NumberValidatorError } from 'bloko/common/numberValidator';

import translation from 'lux/components/translation';
import { NovaFilterKey, Range } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    [NumberValidatorError.NotNumber]: 'require.only.numbers',
    [NumberValidatorError.DecimalLength]: 'formatted.numeric.input.error.decimalLength',
};

export interface ErrorProps {
    scope: Range | null;
    type: NumberValidatorError | null;
}

export interface InputProps {
    name: typeof NovaFilterKey.Salary | typeof NovaFilterKey.Age;
    value: string;
    scope: Range;
    label: string;
    onChange: (value: string, scope: Range) => void;
    updateFilters: () => void;
    error: { inputError: ErrorProps; clearError: () => void };
    inputRef?: RefObject<HTMLInputElement>;
}

const Input: TranslatedComponent<InputProps> = ({
    trls,
    name,
    value,
    scope,
    label,
    onChange,
    updateFilters,
    error: { inputError, clearError },
    inputRef,
}) => {
    const showSearchPreference = useSelector((state) => state.searchPreference.isShown);
    const key = scope ? `${name}_${scope}` : name;
    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.keyCode === KeyCode.Enter) {
            updateFilters();
        }
    };
    return (
        <ControlGroupItem>
            <Tip
                host={!process.env.LUX_SERVER ? document.body : null}
                show={scope === inputError.scope && !!inputError.type}
                theme={TipTheme.Attention}
                render={() => inputError.type && trls[TrlKeys[inputError.type]]}
                onExternalClose={clearError}
            >
                <InputText
                    key={`novafilters-chart-input-${key}-${String(showSearchPreference)}`}
                    data-qa={`novafilters-${key}`}
                    prefix={label}
                    value={value}
                    onBlur={() => updateFilters()}
                    onKeyDown={onKeyDown}
                    onChange={(value) => onChange(value, scope)}
                    ref={inputRef}
                />
            </Tip>
        </ControlGroupItem>
    );
};

export default translation(Input);
