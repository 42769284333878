import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { NovaFilterKey } from 'lux/models/novaFilters';

import NovaFilter from 'lux/components/NovaFilters/components/NovaFilter';

const TrlKeys = {
    title: 'searchvacancy.clusters.schedule',
};

const Schedule: TranslatedComponent = ({ trls }) => {
    return <NovaFilter name={NovaFilterKey.Schedule} title={trls[TrlKeys.title]} />;
};

export default translation(Schedule);
