import Link, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

interface LinkMoreProps {
    length?: number;
    expanded?: boolean;
    onClick?: () => void;
}

const TrlKeys = {
    showMore: 'clusters.showMore',
    hide: 'novafilters.area.hide',
};

const LinkMore: TranslatedComponent<LinkMoreProps> = ({ trls, length, onClick, expanded, children }) => {
    const control = children || (
        <Link appearance={LinkAppearance.Pseudo}>
            {expanded ? trls[TrlKeys.hide] : `${trls[TrlKeys.showMore]} ${length || ''}`}
        </Link>
    );
    return (
        <div
            className="novafilters-list-more"
            onClick={onClick}
            data-qa={expanded ? 'serp__novafilter-item-hide' : 'serp__novafilter-item-more'}
        >
            {control}
        </div>
    );
};

export default translation(LinkMore);
