import { useDispatch } from 'react-redux';

import { useSelector } from 'lux/modules/useSelector';

import getSearchParams from 'lux/components/NovaFilters/actions/sendFilterForm/getSearchParams';
import { ResumeSearchParams } from 'lux/components/NovaFilters/actions/sendFilterForm/getSearchParams/resume';
import { VacancySearchParams } from 'lux/components/NovaFilters/actions/sendFilterForm/getSearchParams/vacancy';
import { VacancyMapSearchParams } from 'lux/components/NovaFilters/actions/sendFilterForm/getSearchParams/vacancyMap';

export const useSearchParams = (): VacancySearchParams | ResumeSearchParams | VacancyMapSearchParams => {
    const dispatch = useDispatch();
    const criteriaCurrencyCode = useSelector(({ criteriaCurrencyCode }) => criteriaCurrencyCode);
    const criteriaTextUpdated = useSelector(({ criteriaTextUpdated }) => criteriaTextUpdated);
    const currencies = useSelector(({ currencies }) => currencies);
    const enableVacancySnippets = useSelector(({ enableVacancySnippets }) => enableVacancySnippets);
    const novaSorts = useSelector(({ novaSorts }) => novaSorts);
    const resumeSearchResult = useSelector(({ resumeSearchResult }) => resumeSearchResult);
    const router = useSelector(({ router }) => router);
    const searchCatalogRedirect = useSelector(({ searchCatalogRedirect }) => searchCatalogRedirect);
    const searchClusters = useSelector(({ searchClusters }) => searchClusters);
    const searchClustersSettings = useSelector(({ searchClustersSettings }) => searchClustersSettings);
    const vacancySearchResult = useSelector(({ vacancySearchResult }) => vacancySearchResult);
    const isAdjustDetectedRegionResumeExpEnabled = useSelector(
        ({ isAdjustDetectedRegionResumeExpEnabled }) => isAdjustDetectedRegionResumeExpEnabled
    );
    const { type: searchType } = searchClustersSettings;

    const searchParams = getSearchParams({
        dispatch,
        criteriaCurrencyCode,
        criteriaTextUpdated,
        currencies,
        enableVacancySnippets,
        router,
        novaSorts,
        resumeSearchResult,
        searchCatalogRedirect,
        searchClusters,
        searchType,
        vacancySearchResult,
        isAdjustDetectedRegionResumeExpEnabled,
    });

    return searchParams;
};
