import { H3Section } from 'bloko/blocks/header';
import InputText from 'bloko/blocks/inputText';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaChipsItem from 'lux/components/NovaFilters/components/Magritte/NovaChipsItem';
import MagritteNovaControl from 'lux/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterContent from 'lux/components/NovaFilters/components/Magritte/NovaFilterContent';
import MagritteNovaFilterWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import NovaControl from 'lux/components/NovaFilters/components/NovaControl';
import NovaFilterContent from 'lux/components/NovaFilters/components/NovaFilterContent';
import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'lux/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'lux/components/translation';
import { NovaFilterKey, NovaFilters } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

import TotalCountBubble from 'lux/components/NovaFilters/vacancies/TotalCountBubble';
import MagritteTotalFiltersCount from 'lux/components/NovaFilters/vacancies/TotalFiltersCount';

const getResumeTitle = (cluster: NovaFilters[typeof NovaFilterKey.Resume], hash: string): string => {
    if (!hash) {
        return '';
    }
    return cluster.groups?.[hash].title;
};

const TrlKeys = {
    filterName: 'novafilters.similarSavedSearch.filter',
    title: 'vacancySearch.clusters.similar.resume',
};

const SimilarResume: TranslatedComponent = ({ trls, ...prop }) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const { enableSimilarSavedSearch, totalUsedFilters, hash, cluster } = useSelector((state) => {
        const resume = state.searchClusters?.[NovaFilterKey.Resume];
        return {
            enableSimilarSavedSearch: state.vacancySearchResult.enableSimilarSavedSearch,
            totalUsedFilters: state.vacancySearchResult.totalUsedFilters,
            hash: resume && resume.selectedValues.length > 0 ? resume.selectedValues[0] : '',
            cluster: resume,
        };
    });
    if (!cluster || Object.values(cluster.groups).length === 0 || !cluster.selectedValues.length) {
        return null;
    }
    const resumeTitle = getResumeTitle(cluster, hash);

    if (prop.isMagritte) {
        return (
            <MagritteNovaControl
                mobileView={
                    <MagritteNovaChipsItem
                        title={trls[TrlKeys.title]}
                        value={resumeTitle}
                        onDelete={() => {
                            filterUpdate([], NovaFilterKey.Resume);
                            sendCountsRequest();
                        }}
                    />
                }
            >
                {enableSimilarSavedSearch && <MagritteTotalFiltersCount />}
                <MagritteNovaFilterWrapper title={trls[TrlKeys.title]}>
                    <MagritteNovaFilterContent name={NovaFilterKey.Resume} {...prop} />
                </MagritteNovaFilterWrapper>
            </MagritteNovaControl>
        );
    }

    return (
        <NovaControl
            mobileView={
                <>
                    <Text Element="span" size={TextSize.Large} strong>
                        {trls[TrlKeys.title]}
                    </Text>
                    <VSpacing base={3} />
                    <InputText
                        showClearButton
                        value={resumeTitle}
                        placeholder={trls[TrlKeys.title]}
                        onChange={() => {
                            filterUpdate([], NovaFilterKey.Resume);
                            sendCountsRequest();
                        }}
                        disabled
                    />
                    <VSpacing base={6} />
                </>
            }
        >
            <div
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                {enableSimilarSavedSearch && (
                    <>
                        <div className="similar-saved-search">
                            <H3Section Element="h1">{trls[TrlKeys.filterName]}</H3Section>
                            <TotalCountBubble
                                totalUsedFilters={totalUsedFilters || 0}
                                resumeHash={hash}
                                showClearLink
                            />
                        </div>
                        <VSpacing base={4} />
                    </>
                )}
                <NovaFilterWrapper title={trls[TrlKeys.title]}>
                    <NovaFilterContent name={NovaFilterKey.Resume} {...prop} truncated />
                </NovaFilterWrapper>
            </div>
        </NovaControl>
    );
};

export default translation(SimilarResume);
