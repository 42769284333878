import { ChangeEvent } from 'react';

import { LanguageLevel as LanguageLevelType } from '@hh.ru/types-hh-microcore';
import ControlGroupItem from 'bloko/blocks/controlGroup/ControlGroupItem';
import Select, { Option } from 'bloko/blocks/select';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { LanguageLevel } from 'lux/models/resumeLanguage';

const TrlKeys = {
    [LanguageLevelType.A1]: 'clusters.language.degree.a1',
    [LanguageLevelType.A2]: 'clusters.language.degree.a2',
    [LanguageLevelType.B1]: 'clusters.language.degree.b1',
    [LanguageLevelType.B2]: 'clusters.language.degree.b2',
    [LanguageLevelType.C1]: 'clusters.language.degree.c1',
    [LanguageLevelType.C2]: 'clusters.language.degree.c2',
    [LanguageLevelType.L1]: 'clusters.language.degree.l1',
};

interface LevelSelectProps {
    grade: string;
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    levels: LanguageLevel[];
    isVertical: boolean;
    dataQa: string;
}

const LanguageLevelSelect: TranslatedComponent<LevelSelectProps> = ({
    trls,
    grade,
    onChange,
    levels,
    isVertical,
    dataQa,
}) => (
    <ControlGroupItem vertical={isVertical}>
        <Select value={grade} onChange={onChange} data-qa={dataQa}>
            {levels.map(({ id, value }) => {
                return (
                    <Option key={id} value={value} data-qa={`${dataQa}-${value}`}>
                        {trls[TrlKeys[value]]}
                    </Option>
                );
            })}
        </Select>
    </ControlGroupItem>
);

export default translation(LanguageLevelSelect);
