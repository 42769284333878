import { ReactNode, FC } from 'react';

import { FormLabel, VSpacing as MagritteVSpacing, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import MagritteNovaBottomSheet from 'lux/components/NovaFilters/components/Magritte/NovaBottomSheet';

interface NovaMobileFilterWithBottomSheetProps {
    title: ReactNode;
    titleModal: ReactNode;
    add: string;
    selected: ReactNode;
    content: ReactNode;
    searchQuery: string;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    onClose: () => void;
    onChangeSearchQuery: (value: string) => void;
    showBack?: boolean;
    onBack: () => void;
    activatorDataQa?: string;
    additionalOptions?: ReactNode;
}

const NovaMobileFilterWithActivator: FC<NovaMobileFilterWithBottomSheetProps> = ({
    setShowModal,
    title,
    titleModal,
    add,
    selected,
    content,
    activatorDataQa,
    ...props
}) => {
    return (
        <>
            <FormLabel>{title}</FormLabel>
            <MagritteVSpacing default={12} />
            {selected}
            <MagritteVSpacing default={12} />
            <MagritteLink
                iconLeft={PlusOutlinedSize16}
                Element="button"
                data-qa={activatorDataQa}
                onClick={() => {
                    setShowModal(true);
                }}
            >
                {add}
            </MagritteLink>
            <MagritteVSpacing default={24} />
            <MagritteNovaBottomSheet title={titleModal} {...props}>
                {content}
            </MagritteNovaBottomSheet>
        </>
    );
};

export default NovaMobileFilterWithActivator;
