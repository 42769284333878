import { ReactElement } from 'react';
import classnames from 'classnames';

import { Checkbox } from '@hh.ru/magritte-ui';

import NovaFilterItemWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import ResetFilter from 'lux/components/NovaFilters/components/Magritte/ResetFilter';
import { NovaFilterKey, NovaFilterRangeConvertedToStrings, NovaFilterGender } from 'lux/models/novaFilters';

import styles from './styles.less';

type AcceptableValueTypes = NovaFilterRangeConvertedToStrings | NovaFilterGender;

interface OnlyWithCheckboxProps<T> {
    values: T;
    onChange: (data: T, forceUpdate?: boolean) => void;
    name: typeof NovaFilterKey.Salary | typeof NovaFilterKey.Age | typeof NovaFilterKey.Gender;
    title: string;
    onReset?: () => void;
}

const OnlyWithCheckbox = <T extends AcceptableValueTypes>({
    values,
    onChange,
    name,
    title,
    onReset,
}: OnlyWithCheckboxProps<T>): ReactElement => {
    return (
        <div className={classnames({ [styles.withReset]: onReset })}>
            <NovaFilterItemWrapper
                left={
                    <Checkbox
                        onChange={() => {
                            onChange({ ...values, onlyWith: !values.onlyWith }, true);
                        }}
                        checked={values.onlyWith}
                        dataQaCheckbox={`serp__novafilter-only_with_${name}`}
                    />
                }
                title={title}
            />
            {onReset && <ResetFilter onReset={onReset} name={name} />}
        </div>
    );
};

export default OnlyWithCheckbox;
