import Radio from 'bloko/blocks/radio';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterCount from 'lux/components/NovaFilters/components/FilterCount';
import FilterTitle from 'lux/components/NovaFilters/components/FilterTitle';
import ListItem from 'lux/components/NovaFilters/components/ListItem';
import translation from 'lux/components/translation';
import { NovaFilterGroupMap } from 'lux/models/novaFilters';

const TrlKeys = {
    doesNotMatter: 'vacancySearch.experience.doesNotMatter',
};
interface Props {
    name: string;
    groups: NovaFilterGroupMap<string>;
    currentValueFrom: string;
    isCustomCompensation: boolean;
    onChangeListValue: (id: string, onlyWithSalary: boolean) => void;
}

const CompensationList: TranslatedComponent<Props> = ({
    groups,
    name,
    trls,
    currentValueFrom,
    isCustomCompensation,
    onChangeListValue,
}) => {
    return (
        <>
            <ListItem>
                <Radio
                    onChange={() => onChangeListValue('', false)}
                    checked={!currentValueFrom && !isCustomCompensation}
                >
                    {trls[TrlKeys.doesNotMatter]}
                </Radio>
            </ListItem>
            {Object.values(groups).map((group) => {
                if (group.id === 'only_with_salary') {
                    return null;
                }
                return (
                    <ListItem key={group.id}>
                        <Radio
                            name={name}
                            value={group.id}
                            onChange={({ target: { value } }) => onChangeListValue(value, true)}
                            checked={currentValueFrom === group.id && !isCustomCompensation}
                            labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                        >
                            <FilterTitle title={group.title} />
                            <FilterCount count={group.count} />
                        </Radio>
                    </ListItem>
                );
            })}
        </>
    );
};

export default translation(CompensationList);
