import { FC, ReactNode, useRef } from 'react';
import classnames from 'classnames';

import { TooltipHover } from '@hh.ru/magritte-ui';

import styles from 'lux/components/NovaFilters/components/Magritte/NovaFilterWithChart/styles.less';

export interface NovaFiltersChartItem {
    height: number;
    title: ReactNode;
    count: number;
    from?: string | number | null;
    to?: string | number | null;
    id: string;
    showHover: boolean;
    isSelected?: boolean;
}

interface NovaFiltersChartItemProps extends NovaFiltersChartItem {
    onClick: (data: NovaFiltersChartItem) => void;
}

const Bar: FC<NovaFiltersChartItemProps> = ({ height, title, count, from, to, id, showHover, onClick, isSelected }) => {
    const activatorRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <div
                ref={activatorRef}
                className={classnames(styles.bar, {
                    [styles.barDisabled]: !showHover,
                    [styles.barSelected]: showHover && isSelected,
                })}
                style={{ height: `${height}px` }}
                onClick={() => showHover && onClick({ height, title, count, from, to, id, showHover })}
            />
            {showHover && (
                <TooltipHover activatorRef={activatorRef} placement="top-center">
                    {title}
                </TooltipHover>
            )}
        </>
    );
};

export default Bar;
