import { useState, useMemo, useCallback, useEffect } from 'react';

import { Select, FormLabel, VSpacing as MagritteVSpacing, Link as MagritteLink, Button } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { InputChangeHandler } from 'bloko/blocks/inputText';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import debounce from 'bloko/common/debounce';
import FuzzySearch from 'bloko/common/fuzzySearch';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaChipsList from 'lux/components/NovaFilters/components/Magritte/NovaChipsList';
import NovaChipsList from 'lux/components/NovaFilters/components/NovaChipsList';
import NovaFiltersItem from 'lux/components/NovaFilters/components/NovaFiltersItem';
import NovaModal from 'lux/components/NovaFilters/components/NovaModal';
import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'lux/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'lux/components/translation';
import { NovaFilterKey, NovaFilterGroup } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    add: 'vacancySearch.neighboursArea.add',
    search: 'vacancySearch.neighboursArea.search',
    title: 'vacancySearch.neighboursArea.title',
    save: 'novaModal.save',
};

const NeighboursAreaMobile: TranslatedComponent<{ title: string; isMagritte?: boolean }> = ({
    trls,
    title,
    isMagritte,
}) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();

    // global state data
    const { groups: neighbourAreaGroups, selectedValues: initValues } = useSelector(
        (state) => state.searchClusters?.[NovaFilterKey.Neighbours]
    );

    // chips list
    const [neighbourAreas, setNeighbourAreas] = useState<NovaFilterGroup<string>[]>([]);
    // search list
    const [searchNeighbourAreas, setSearchNeighbourAreas] = useState<NovaFilterGroup<string>[]>([]);
    const [selectedNeighbourAreas, setSelectedNeighbourAreas] = useState<string[]>(
        initValues?.map((item) => `${item}`) || []
    );

    // modal data
    const [showModal, setShowModal] = useState<boolean>(false);
    const [searchQuery, setQuery] = useState<string>('');

    // need update list after spa/ajax requests
    useEffect(() => {
        setNeighbourAreas(Object.values(neighbourAreaGroups));
    }, [neighbourAreaGroups]);

    // reload list after close modal
    useEffect(() => {
        if (!showModal) {
            setSearchNeighbourAreas(Object.values(neighbourAreaGroups));
        }
    }, [neighbourAreaGroups, showModal]);

    const optionsChips = useMemo(
        () => neighbourAreas.filter(({ id }) => selectedNeighbourAreas.includes(id)),
        [neighbourAreas, selectedNeighbourAreas]
    );

    const optionsSelect = useMemo(
        () =>
            searchNeighbourAreas.map((item) => ({
                label: item.title,
                value: item.id,
            })),
        [searchNeighbourAreas]
    );

    const onChangeNeighbours = useCallback(
        (area: string) => {
            // update area states
            const index = selectedNeighbourAreas.indexOf(area);
            const newSelectedNeighbours = [...selectedNeighbourAreas];
            if (index === -1) {
                newSelectedNeighbours.push(area);
            } else {
                newSelectedNeighbours.splice(index, 1);
            }
            setSelectedNeighbourAreas(newSelectedNeighbours);
            filterUpdate(newSelectedNeighbours, NovaFilterKey.Neighbours);
        },
        [filterUpdate, selectedNeighbourAreas]
    );

    const onChangeNeighboursBySelect = useCallback(
        (neighbours: string[]) => {
            setSelectedNeighbourAreas(neighbours);
            filterUpdate(neighbours, NovaFilterKey.Neighbours);
        },
        [filterUpdate]
    );

    const searchNeighbourAreasDebounced = useCallback(
        debounce((searchText: string) => {
            const list: NovaFilterGroup<string>[] = Object.values(neighbourAreaGroups);
            if (searchText.length === 0) {
                setSearchNeighbourAreas(list);
                return;
            }
            setSearchNeighbourAreas(list.filter((area) => FuzzySearch.match(searchText, area.title)));
        }, 400),
        []
    );

    const onChangeSearchQuery = useCallback<InputChangeHandler>(
        (value) => {
            setQuery(value);
            searchNeighbourAreasDebounced(value);
        },
        [searchNeighbourAreasDebounced]
    );

    const onCloseOrBack = () => {
        setShowModal(false);
        setQuery('');
        sendCountsRequest();
    };

    if (isMagritte) {
        return (
            <>
                <FormLabel>{trls[TrlKeys.title]}</FormLabel>
                <MagritteVSpacing default={12} />
                <MagritteNovaChipsList
                    name={NovaFilterKey.Neighbours}
                    options={optionsChips}
                    onClear={(id) => {
                        onChangeNeighbours(id);
                        sendCountsRequest();
                    }}
                />
                <MagritteVSpacing default={12} />
                <Select
                    searchable
                    multiple
                    value={selectedNeighbourAreas}
                    name={NovaFilterKey.Neighbours}
                    options={optionsSelect}
                    placeholder={trls[TrlKeys.title]}
                    showCloseButtonInNavigationBar
                    renderActivator={({ toggleOptions }) => {
                        return (
                            <MagritteLink
                                iconLeft={PlusOutlinedSize16}
                                Element="button"
                                data-qa="novafilters-mobile-add-neighbours-areas"
                                onClick={() => toggleOptions(true)}
                            >
                                {trls[TrlKeys.add]}
                            </MagritteLink>
                        );
                    }}
                    onChange={onChangeNeighboursBySelect}
                    applyChangesButton={
                        <Button mode="primary" style="accent">
                            {trls[TrlKeys.save]}
                        </Button>
                    }
                />
                <MagritteVSpacing default={24} />
            </>
        );
    }

    return (
        <>
            <Text Element="span" size={TextSize.Large} strong>
                {trls[TrlKeys.title]}
            </Text>
            <VSpacing base={3} />
            <NovaChipsList
                name={NovaFilterKey.Neighbours}
                options={optionsChips}
                onClear={(id) => {
                    onChangeNeighbours(id);
                    sendCountsRequest();
                }}
            />
            <VSpacing base={4} />
            <Link
                appearance={LinkAppearance.Pseudo}
                data-qa="novafilters-mobile-add-neighbours-areas"
                onClick={() => {
                    setShowModal(!showModal);
                }}
            >
                {trls[TrlKeys.add]}
            </Link>
            <VSpacing base={6} />
            <NovaModal
                onChangeSearchQuery={onChangeSearchQuery}
                searchQuery={searchQuery}
                title={title}
                searchPlaceholder={title}
                showModal={showModal}
                onBack={onCloseOrBack}
                onClose={onCloseOrBack}
            >
                {showModal &&
                    searchNeighbourAreas.map((item) => (
                        <NovaFiltersItem
                            key={item.id}
                            name={NovaFilterKey.Neighbours}
                            item={item}
                            onChange={onChangeNeighbours}
                            checked={selectedNeighbourAreas.includes(item.id)}
                            hideCount
                        />
                    ))}
            </NovaModal>
        </>
    );
};

export default translation(NeighboursAreaMobile);
