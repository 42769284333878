import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonScale, ButtonAppearance } from 'bloko/blocks/button';
import ClickInfo, { InfoTheme, InfoPlacement } from 'bloko/blocks/drop/Info/Click';
import { InfoPlacementValue } from 'bloko/blocks/drop/Info/constants';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { NovaFilterSetting } from 'lux/components/NovaFilters/novaFilterUtils';
import translation from 'lux/components/translation';
import paths from 'lux/modules/routePaths';

interface TotalCountBubbleProps {
    totalUsedFilters: number;
    placement?: InfoPlacementValue;
    resumeHash?: string;
    showClearLink?: boolean;
}

const TrlKeys = {
    clear: 'novafilters.similarSavedSearch.clear',
    explanation: 'novafilters.similarSavedSearch.explanation',
    confirm: 'novafilters.similarSavedSearch.confirm',
};

const TotalCountBubble: TranslatedComponent<TotalCountBubbleProps> = ({
    trls,
    totalUsedFilters,
    placement = InfoPlacement.TopEnd,
    resumeHash = '',
    showClearLink = false,
}) => {
    if (totalUsedFilters < 1) {
        return null;
    }
    return (
        <>
            <ClickInfo
                render={(close) => (
                    <div>
                        <p className="similar-saved-search__explanation">{trls[TrlKeys.explanation]}</p>
                        <Button
                            kind={ButtonKind.Inversed}
                            appearance={ButtonAppearance.Outlined}
                            onClick={close}
                            scale={ButtonScale.Small}
                        >
                            {trls[TrlKeys.confirm]}
                        </Button>
                    </div>
                )}
                placement={placement}
                theme={InfoTheme.Dark}
            >
                <div className="similar-saved-search__counter">{totalUsedFilters}</div>
            </ClickInfo>
            {showClearLink && (
                <div className="similar-saved-search__clear">
                    <Text size={TextSize.Small}>
                        <BlokoLink
                            to={`${paths.vacancySearch}?resume=${resumeHash}&${NovaFilterSetting.ForceFiltersSaving}=true`}
                            Element={Link}
                        >
                            {trls[TrlKeys.clear]}
                        </BlokoLink>
                    </Text>
                </div>
            )}
        </>
    );
};

export default translation(TotalCountBubble);
