import { useState } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import ResumeLanguageFilterWithVerification from 'lux/components/ResumeLanguageFilter/ResumeLanguageFilterWithVerification';
import VerifiedFilterInfoTip from 'lux/components/VerifiedFilterInfoTip/VerifiedFilterInfoTip';
import translation from 'lux/components/translation';
import { NovaFilterKey } from 'lux/models/novaFilters';
import { VerifiedSkillCategory } from 'lux/models/resume/resumeVerifiedSkills.types';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    title: 'novafilters.language',
    addVerified: 'clusters.verifiedLanguages',
};

interface Props {
    active: boolean;
}

const Language: TranslatedComponent<Props> = ({ trls, active = false }) => {
    const filterUpdate = useNovaFilterUpdate();
    const searchClusters = useSelector(({ searchClusters }) => searchClusters);
    const selectedValues = searchClusters[NovaFilterKey.Language]?.selectedValues;

    const [selectedLanguages, setSelectedLanguages] = useState(selectedValues);

    const onChangeLanguages = (newLanguages: string[]) => {
        setSelectedLanguages(newLanguages);
        filterUpdate(newLanguages, NovaFilterKey.Language);
    };
    if (!active) {
        return <>{trls[TrlKeys.title]}</>;
    }
    if (!selectedLanguages) {
        return null;
    }
    return (
        <div style={{ position: 'relative' }}>
            <VerifiedFilterInfoTip category={VerifiedSkillCategory.Lang}>
                <NovaFilterWrapper title={trls[TrlKeys.title]}>
                    <ResumeLanguageFilterWithVerification
                        value={selectedLanguages}
                        onChange={onChangeLanguages}
                        preTitleDataQa="resume-search"
                    />
                </NovaFilterWrapper>
            </VerifiedFilterInfoTip>
        </div>
    );
};

export default translation(Language);
