import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaChipsSelect, { ChipsSelectType } from 'lux/components/NovaFilters/components/NovaChipsSelect';
import useNovaSortUpdate from 'lux/components/NovaFilters/hooks/useNovaSortUpdate';
import translation from 'lux/components/translation';
import { OrderByOption } from 'lux/models/search/searchCriteria.types';
import { useSelector } from 'lux/modules/useSelector';

import styles from './sorts.less';

const TrlKeys = {
    sortTitle: 'search.vacancy.clusters.sort.title',
    [OrderByOption.PublicationTime]: 'vacancySearchResults.sortCode.publication_time',
    [OrderByOption.SalaryDesc]: 'vacancySearchResults.sortCode.salary_desc',
    [OrderByOption.SalaryAsc]: 'vacancySearchResults.sortCode.salary_asc',
    [OrderByOption.Relevance]: 'vacancySearchResults.sortCode.relevance',
};

const NovaSearchOrder: TranslatedComponent = ({ trls }) => {
    const value = useSelector((state) => state.novaSorts.orderBy);
    const options = useSelector((state) => state.vacancySearchDictionaries.orderBy);
    const handleChange = useNovaSortUpdate();

    if (!options) {
        return null;
    }

    return (
        <div>
            <Text Element="span" size={TextSize.Large} strong>
                {trls[TrlKeys.sortTitle]}
            </Text>
            <VSpacing base={3} />
            <NovaChipsSelect
                name={'order_by'}
                selected={[value || '']}
                data-qa="serp-settings__order-by-menu"
                options={options.map((option) => ({
                    id: option,
                    title: <p className={styles.novaSort}>{trls[TrlKeys[option]]}</p>,
                }))}
                selectType={ChipsSelectType.Single}
                onChange={(values) => {
                    handleChange('orderBy', values[0] as OrderByOption);
                }}
            />
            <VSpacing base={6} />
        </div>
    );
};

export default translation(NovaSearchOrder);
