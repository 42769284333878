import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilterWithAdditionalList from 'lux/components/NovaFilters/components/NovaFilterWithAdditionalList';
import translation from 'lux/components/translation';
import { useAreasDataProvider } from 'lux/hooks/useAreasDataProvider';
import { NovaFilterKey } from 'lux/models/novaFilters';

const TrlKeys = {
    title: 'searchvacancy.clusters.region',
    placeholder: 'novafilters.area.search',
    add: 'vacancySearch.area.add',
};

const Area: TranslatedComponent<{ isMagritte?: boolean; title: string }> = ({ isMagritte, trls }) => {
    const dataProvider = useAreasDataProvider(true);

    return (
        <NovaFilterWithAdditionalList
            filterName={NovaFilterKey.Area}
            dataProvider={dataProvider}
            title={trls[TrlKeys.title]}
            placeholder={trls[TrlKeys.placeholder]}
            placeholderMobile={trls[TrlKeys.title]}
            add={trls[TrlKeys.add]}
            isMagritte={isMagritte}
        />
    );
};

export default translation(Area);
