import { FC } from 'react';

import { CustomChip, ChipsContainer } from '@hh.ru/magritte-ui';

import { NovaChipsOption } from 'lux/components/NovaFilters/components/Magritte/NovaChipsSelect';

export interface NovaChipsSelectProps {
    name: string;
    options: NovaChipsOption<string>[];
    onClear: (id: string) => void;
}

const NovaChipsList: FC<NovaChipsSelectProps> = ({ name, options, onClear }) => {
    if (options.length === 0) {
        return null;
    }

    return (
        <ChipsContainer scrollable noWrap>
            {options.map(({ id, title, addon }) => (
                <CustomChip
                    key={`${name}_${id}`}
                    id={id}
                    value={id}
                    data-qa={`serp__novafilter-xs-${name}-${id}`}
                    name={name}
                    onDelete={() => onClear(id)}
                    addon={addon}
                >
                    {title}
                </CustomChip>
            ))}
        </ChipsContainer>
    );
};

export default NovaChipsList;
