import { useState } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterList from 'lux/components/NovaFilters/components/FilterList';
import LinkMore from 'lux/components/NovaFilters/components/LinkMore';
import NovaControl from 'lux/components/NovaFilters/components/NovaControl';
import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';
import NovaFiltersItem from 'lux/components/NovaFilters/components/NovaFiltersItem';
import NovaXSFilter from 'lux/components/NovaFilters/components/NovaXSFilter';
import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import useNovaFiltersGroups from 'lux/components/NovaFilters/hooks/useNovaFiltersGroups';
import VerifiedFilterInfoTip from 'lux/components/VerifiedFilterInfoTip/VerifiedFilterInfoTip';
import translation from 'lux/components/translation';
import { NovaFilterGroup, NovaFilterKey } from 'lux/models/novaFilters';
import { VerifiedSkillCategory } from 'lux/models/resume/resumeVerifiedSkills.types';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    title: 'clusters.verifiedSkills',
};

const EMPTY_ORDERS = [] as string[];

interface VerifiedSkillsFilterProps {
    sortFunc?: (a: NovaFilterGroup<string>, b: NovaFilterGroup<string>) => number;
    shouldSendCountsRequest?: boolean;
    truncated?: boolean;
}

const VerifiedSkillsFilter: TranslatedComponent<VerifiedSkillsFilterProps> = ({
    trls,
    sortFunc,
    truncated = false,
    ...props
}) => {
    const filterUpdate = useNovaFilterUpdate();
    const orders = useSelector((state) => state.searchClustersOrder?.[NovaFilterKey.VerifiedSkills] || EMPTY_ORDERS);
    const searchClusters = useSelector(({ searchClusters }) => searchClusters);
    const { groups, selectedValues } = searchClusters[NovaFilterKey.VerifiedSkills];

    const [expanded, setExpanded] = useState(false);
    const { items, breakpoint } = useNovaFiltersGroups(groups, selectedValues.length, orders, expanded, sortFunc);
    const handleChange = (item: NovaFilterGroup<string>) => {
        const { id } = item;
        const selectedStringedValues = selectedValues.map((value) => value.toString());
        const index = selectedStringedValues.indexOf(id);
        const newValues = [...selectedValues];
        if (index === -1) {
            newValues.push(+id);
        } else {
            newValues.splice(index, 1);
        }
        filterUpdate(newValues, NovaFilterKey.VerifiedSkills);
    };

    if (!items.length) {
        return null;
    }

    return (
        <NovaControl
            mobileView={<NovaXSFilter {...props} name={NovaFilterKey.VerifiedSkills} title={trls[TrlKeys.title]} />}
        >
            <VerifiedFilterInfoTip category={VerifiedSkillCategory.Skill}>
                <NovaFilterWrapper title={trls[TrlKeys.title]}>
                    <FilterList expanded={expanded} len={items.length}>
                        {items.map((item, index) => {
                            if (index > breakpoint - 1 && !expanded) {
                                return null;
                            }
                            return (
                                <NovaFiltersItem
                                    key={item.id}
                                    item={item}
                                    name={NovaFilterKey.VerifiedSkills}
                                    onChange={() => handleChange(item)}
                                    checked={selectedValues.includes(+item.id)}
                                    truncated={truncated}
                                />
                            );
                        })}
                    </FilterList>
                    {items.length > breakpoint && (
                        <LinkMore
                            length={items.length - breakpoint}
                            expanded={expanded}
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                        />
                    )}
                </NovaFilterWrapper>
            </VerifiedFilterInfoTip>
        </NovaControl>
    );
};

export default translation(VerifiedSkillsFilter);
