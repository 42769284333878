import { FC } from 'react';

import UniversitySuggestBloko from 'lux/components/NovaFilters/components/Bloko/resumes/UniversitySuggest';
import UniversitySuggestMagritte from 'lux/components/NovaFilters/components/Magritte/resumes/UniversitySuggest';
import translation from 'lux/components/translation';
import useMagritte from 'lux/hooks/useMagritte';

const UniversitySuggest: FC = () => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? UniversitySuggestMagritte : UniversitySuggestBloko;

    return <Component />;
};

export default translation(UniversitySuggest);
