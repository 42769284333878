import { Fragment, useEffect, useState, FC } from 'react';

import { Badge, Text, VSpacing as MagritteVSpacing, HSpacing } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { ChevronScaleSmallKindRight, IconColor } from 'bloko/blocks/icon';
import VSpacing from 'bloko/blocks/vSpacing';
import FuzzySearch from 'bloko/common/fuzzySearch';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { AdditionalDefault, CoreField, TreeModel } from 'bloko/common/tree/types';

import styles from './parent-mobile-filter.less';

interface ParentFilterPros {
    collection: TreeCollection<AdditionalDefault>;
    searchText: string;
    selectedValues: string[];
    onChange: (id: string) => void;
    isMagritte?: boolean;
}

const ParentMobileFilter: FC<ParentFilterPros> = ({ collection, searchText, selectedValues, onChange, isMagritte }) => {
    const [categories, setCategories] = useState<TreeModel<AdditionalDefault>[]>([]);

    useEffect(() => {
        const topLevelItems = collection.getTopLevel();
        if (!searchText) {
            setCategories(topLevelItems);
            return;
        }
        // collect all parents
        const categoryMap: Record<string, TreeModel<AdditionalDefault>> = {};
        collection.walk((category, currentParents) => {
            if (FuzzySearch.match(searchText, category[CoreField.Text])) {
                if (currentParents.length > 0) {
                    // for matched child add parent to results
                    const parent = currentParents[0];
                    categoryMap[parent[CoreField.Id]] = parent;
                    return;
                }
                categoryMap[category[CoreField.Id]] = category;
            }
        });
        // save original sort
        const filtered = Object.values(categoryMap).sort((catA, catB) => {
            if (catA[CoreField.Text] > catB[CoreField.Text]) {
                return 1;
            }
            if (catA[CoreField.Text] < catB[CoreField.Text]) {
                return -1;
            }
            return 0;
        });
        setCategories(filtered);
    }, [collection, searchText]);

    return (
        <div>
            {categories.map((category) => {
                const id = category[CoreField.Id];
                const children = collection.getChildrenIds(id);
                const selected = selectedValues.includes(id)
                    ? children.length
                    : selectedValues.filter((value) => children.includes(value)).length;

                if (isMagritte) {
                    return (
                        <Fragment key={id}>
                            <div
                                className={styles.parentFilter}
                                onClick={() => {
                                    onChange(id);
                                }}
                            >
                                <Text>{category[CoreField.Text]}</Text>
                                <div className={styles.parentFilterActions}>
                                    {selected > 0 && <Badge>{`${selected}`}</Badge>}
                                    <HSpacing default={4} />
                                    <ChevronRightOutlinedSize24 initial="secondary" />
                                </div>
                            </div>
                            <MagritteVSpacing default={24} />
                        </Fragment>
                    );
                }
                return (
                    <Fragment key={id}>
                        <div
                            className={styles.parentFilter}
                            onClick={() => {
                                onChange(id);
                            }}
                        >
                            <div>{category[CoreField.Text]}</div>
                            <div className={styles.parentFilterActions}>
                                {selected > 0 && <span className={styles.parentFilterLabel}>{selected}</span>}
                                <ChevronScaleSmallKindRight initial={IconColor.Gray50} />
                            </div>
                        </div>
                        <VSpacing base={5} />
                    </Fragment>
                );
            })}
        </div>
    );
};

export default ParentMobileFilter;
