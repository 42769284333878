import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaControl from 'lux/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterContent from 'lux/components/NovaFilters/components/Magritte/NovaFilterContent';
import MagritteNovaFilterWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import MagritteNovaMobileFilter from 'lux/components/NovaFilters/components/Magritte/NovaMobileFilter';
import NovaControl from 'lux/components/NovaFilters/components/NovaControl';
import { NovaFilterProps } from 'lux/components/NovaFilters/components/NovaFilter';
import NovaFilterContent from 'lux/components/NovaFilters/components/NovaFilterContent';
import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';
import NovaXSFilter from 'lux/components/NovaFilters/components/NovaXSFilter';
import translation from 'lux/components/translation';
import { NovaFilterKey } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

import AcceptTemporary from 'lux/components/NovaFilters/vacancies/AcceptTemporary';

const TrlKeys = {
    title: 'searchvacancy.clusters.employment',
};

const Employment: TranslatedComponent<NovaFilterProps & { isMagritte?: boolean }> = ({
    trls,
    name,
    isMagritte,
    ...props
}) => {
    const acceptTemporary = useSelector((state) => state.searchClusters[NovaFilterKey.AcceptTemporary]);

    if (isMagritte) {
        return (
            <>
                <MagritteNovaControl
                    mobileView={
                        <MagritteNovaMobileFilter
                            {...props}
                            name={name}
                            title={trls[TrlKeys.title]}
                            withDivider={Boolean(acceptTemporary)}
                        />
                    }
                >
                    <MagritteNovaFilterWrapper title={trls[TrlKeys.title]} withDivider={Boolean(acceptTemporary)}>
                        <MagritteNovaFilterContent {...props} name={name} />
                    </MagritteNovaFilterWrapper>
                </MagritteNovaControl>
                {Boolean(acceptTemporary) && <AcceptTemporary isMagritte />}
            </>
        );
    }

    return (
        <>
            <NovaControl
                mobileView={
                    <NovaXSFilter
                        {...props}
                        name={name}
                        title={trls[TrlKeys.title]}
                        vBase={acceptTemporary ? 5 : undefined}
                    />
                }
            >
                <NovaFilterWrapper title={trls[TrlKeys.title]} divider={Boolean(acceptTemporary)}>
                    <NovaFilterContent {...props} name={name} />
                </NovaFilterWrapper>
            </NovaControl>
            {Boolean(acceptTemporary) && <AcceptTemporary />}
        </>
    );
};

export default translation(Employment);
