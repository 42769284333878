import { FC, ReactNode, PropsWithChildren } from 'react';

import useMagritte from 'lux/hooks/useMagritte';

import NovaFilterWrapperBloko from 'lux/components/NovaFilters/components/Bloko/NovaFilterWrapper';
import NovaFilterWrapperMagritte from 'lux/components/NovaFilters/components/Magritte/NovaFilterWrapper';

interface NovaFilterWrapperPros {
    title?: ReactNode;
    titleSideElement?: ReactNode;
    divider?: boolean;
}

const NovaFilterWrapper: FC<NovaFilterWrapperPros & PropsWithChildren> = (props) => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? NovaFilterWrapperMagritte : NovaFilterWrapperBloko;

    return <Component {...props} />;
};

export default NovaFilterWrapper;
