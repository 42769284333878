import { ReactNode } from 'react';

import { Link as MagritteLink, Loader } from '@hh.ru/magritte-ui';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import { DataProvider } from 'bloko/blocks/suggest/types';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterList from 'lux/components/NovaFilters/components/FilterList';
import LinkMore from 'lux/components/NovaFilters/components/LinkMore';
import MagritteFilterList from 'lux/components/NovaFilters/components/Magritte/FilterList';
import MagritteLinkMore from 'lux/components/NovaFilters/components/Magritte/LinkMore';
import MagritteNovaControl from 'lux/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import MagritteNovaFiltersItem from 'lux/components/NovaFilters/components/Magritte/NovaFiltersItem';
import NovaControl from 'lux/components/NovaFilters/components/NovaControl';
import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';
import NovaFiltersItem from 'lux/components/NovaFilters/components/NovaFiltersItem';
import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import useNovaFiltersGroups from 'lux/components/NovaFilters/hooks/useNovaFiltersGroups';
import translation from 'lux/components/translation';
import { NovaFilterKey } from 'lux/models/novaFilters';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';

import AdditionalList, {
    ItemMap,
} from 'lux/components/NovaFilters/components/NovaFilterWithAdditionalList/AdditionalList';
import MobileList from 'lux/components/NovaFilters/components/NovaFilterWithAdditionalList/MobileList';
import { useAdditionalList } from 'lux/components/NovaFilters/components/NovaFilterWithAdditionalList/useAdditionalList';

const TrlKeys = {
    hide: 'novafilters.additionalList.hide',
    show: 'novafilters.additionalList.show',
};

interface NovaFilterWithAdditionalListProps {
    filterName: typeof NovaFilterKey.Area | typeof NovaFilterKey.District;
    dataProvider: DataProvider;
    title: ReactNode;
    placeholder: string;
    placeholderMobile?: string;
    add: string;
    isMagritte?: boolean;
}

const NovaFilterWithAdditionalList: TranslatedComponent<NovaFilterWithAdditionalListProps> = ({
    filterName,
    dataProvider,
    title,
    placeholder,
    placeholderMobile,
    add,
    isMagritte,
    trls,
}) => {
    const filterUpdate = useNovaFilterUpdate();
    const orders = useSelector((state) => state.searchClustersOrder?.[filterName]) || [];
    const { groups, selectedValues } = useSelector(({ searchClusters }) => searchClusters?.[filterName]);

    const { isLoading, list, showAdditionalList, toggleShowAdditionalList } = useAdditionalList(filterName);

    const { items, breakpoint } = useNovaFiltersGroups(groups, selectedValues.length, orders, false);

    const onChange = (selected: string) => {
        const newSelected = Number(selected);
        const index = selectedValues.indexOf(newSelected);
        const newSelectedValues = [...selectedValues];
        if (index === -1) {
            newSelectedValues.push(newSelected);
        } else {
            newSelectedValues.splice(index, 1);
        }
        filterUpdate(newSelectedValues, filterName);
    };

    if (isMagritte) {
        return (
            <MagritteNovaControl
                mobileView={
                    <MobileList
                        filterName={filterName}
                        dataProvider={dataProvider}
                        title={title}
                        placeholder={placeholderMobile || placeholder}
                        add={add}
                        isMagritte={isMagritte}
                    />
                }
            >
                <MagritteNovaFilterWrapper title={title}>
                    {showAdditionalList ? (
                        <AdditionalList
                            // showAdditionalList can be true only when list are already loaded
                            allItems={list as ItemMap}
                            selectedItems={selectedValues}
                            onChange={onChange}
                            placeholder={placeholder}
                            dataProvider={dataProvider}
                            filterName={filterName}
                            isMagritte
                        />
                    ) : (
                        <MagritteFilterList>
                            {items.map(
                                (item, index) =>
                                    index < breakpoint && (
                                        <MagritteNovaFiltersItem
                                            key={item.id}
                                            item={item}
                                            name={filterName}
                                            onChange={onChange}
                                            checked={selectedValues.includes(Number(item.id))}
                                        />
                                    )
                            )}
                        </MagritteFilterList>
                    )}
                    {Object.keys(groups).length > 0 && (
                        <MagritteLinkMore expanded={showAdditionalList}>
                            <MagritteLink Element="button" onClick={toggleShowAdditionalList} inline>
                                {trls[showAdditionalList ? TrlKeys.hide : TrlKeys.show]}
                            </MagritteLink>
                            {NON_BREAKING_SPACE}
                            {isLoading && <Loader />}
                        </MagritteLinkMore>
                    )}
                </MagritteNovaFilterWrapper>
            </MagritteNovaControl>
        );
    }

    return (
        <NovaControl
            mobileView={
                <MobileList
                    filterName={filterName}
                    dataProvider={dataProvider}
                    title={title}
                    placeholder={placeholderMobile || placeholder}
                    add={add}
                />
            }
        >
            <NovaFilterWrapper title={title}>
                {showAdditionalList ? (
                    <AdditionalList
                        // showAdditionalList can be true only when list are already loaded
                        allItems={list as ItemMap}
                        selectedItems={selectedValues}
                        onChange={onChange}
                        placeholder={placeholder}
                        dataProvider={dataProvider}
                        filterName={filterName}
                    />
                ) : (
                    <FilterList>
                        {items.map(
                            (item, index) =>
                                index < breakpoint && (
                                    <NovaFiltersItem
                                        key={item.id}
                                        item={item}
                                        name={filterName}
                                        onChange={onChange}
                                        checked={selectedValues.includes(Number(item.id))}
                                    />
                                )
                        )}
                    </FilterList>
                )}
                {Object.keys(groups).length > 0 && (
                    <LinkMore expanded={showAdditionalList}>
                        <Link appearance={LinkAppearance.Pseudo} onClick={toggleShowAdditionalList}>
                            {trls[showAdditionalList ? TrlKeys.hide : TrlKeys.show]}
                        </Link>
                        {NON_BREAKING_SPACE}
                        {isLoading && <Loading scale={LoadingScale.Small} />}
                    </LinkMore>
                )}
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(NovaFilterWithAdditionalList);
