import { FC } from 'react';

import { Checkbox } from '@hh.ru/magritte-ui';

import { NovaFilterGroup } from 'lux/models/novaFilters';

import NovaFilterItemWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';

interface NovaFiltersItemProps {
    item: NovaFilterGroup<string | number>;
    name: string;
    checked: boolean;
    onChange: (id: string) => void;
}

const NovaFiltersItem: FC<NovaFiltersItemProps> = ({
    item: { title, count, id, ...prop },
    name,
    checked,
    onChange,
}) => {
    const disabledItem = prop?.disabled;

    return (
        <NovaFilterItemWrapper
            left={
                <Checkbox
                    name={name}
                    value={id}
                    onChange={() => {
                        onChange(`${id}`);
                    }}
                    checked={checked}
                    dataQaCheckbox={`serp__novafilter-${name}-${id}`}
                />
            }
            title={title}
            count={count}
            disabled={disabledItem}
        />
    );
};

export default NovaFiltersItem;
