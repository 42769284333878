import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { NovaSort } from 'lux/models/vacancySearch/novaSorts';
import { useSelector } from 'lux/modules/useSelector';

const novaSortAction = makeSetStoreField('novaSorts');
const searchChangedClustersAction = makeSetStoreField('searchClustersChanged');

interface NovaSortUpdate {
    <F extends keyof NovaSort>(name: F, value: NovaSort[F]): void;
}

export default (): NovaSortUpdate => {
    const dispatch = useDispatch();
    const novaSorts = useSelector((state) => state.novaSorts);
    return (name, value) => {
        const newSorts = { ...novaSorts };
        newSorts[name] = value;
        dispatch([novaSortAction(newSorts), searchChangedClustersAction(true)]);
    };
};
