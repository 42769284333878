import { Link as MagritteLink, VSpacing, Divider } from '@hh.ru/magritte-ui';
import Link from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { usePlatform } from 'lux/hooks/usePlatform';
import { Platform } from 'lux/models/locale.types';
import { useSelector } from 'lux/modules/useSelector';

import SearchType from 'lux/components/NovaFilters/SearchType';

const ARTICLE_LINKS = {
    [SearchType.Vacancy]: { [Platform.HeadHunter]: '/article/1175', [Platform.Zarplata]: undefined },
    [SearchType.VacancyMap]: { [Platform.HeadHunter]: '/article/1175', [Platform.Zarplata]: undefined },
    [SearchType.Resume]: {
        [Platform.HeadHunter]: '/article/23786',
        [Platform.Zarplata]: '/article/25295?hhtmFrom=resume_search_form',
    },
};

const TrlKeys = {
    link: 'novafilters.searchLanguageLink',
};

interface Props {
    searchType: SearchType;
    isMagritte?: boolean;
}

const SearchLanguageLink: TranslatedComponent<Props> = ({ trls, searchType, isMagritte }) => {
    const isResumeSearch = useSelector((state) => state.searchClustersSettings.type) === SearchType.Resume;
    const platform = usePlatform();

    if (isMagritte) {
        return (
            <>
                {!isResumeSearch && <Divider marginBottom={24} />}
                <MagritteLink
                    href={ARTICLE_LINKS[searchType][platform] || ARTICLE_LINKS[searchType][Platform.HeadHunter]}
                >
                    {trls[TrlKeys.link]}
                </MagritteLink>
                <VSpacing default={24} />
            </>
        );
    }
    return (
        <div>
            <div className="novafilters-separator" />
            <div className="novafilters-group-wrapper">
                <Link href={ARTICLE_LINKS[searchType][platform] || ARTICLE_LINKS[searchType][Platform.HeadHunter]}>
                    {trls[TrlKeys.link]}
                </Link>
            </div>
        </div>
    );
};

export default translation(SearchLanguageLink);
