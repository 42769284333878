import { useCallback, useRef, FC } from 'react';

import { HSpacingContainer } from '@hh.ru/magritte-ui';

import { NovaFilterKey, Range, NovaFilterRangeConvertedToStrings } from 'lux/models/novaFilters';

import NovaFilterRangeInput, {
    InputProps,
} from 'lux/components/NovaFilters/components/Magritte/NovaFilterWithChart/Input';

export interface NovaFilterRangeTrls {
    from: string;
    to: string;
    fromTo: string;
    labelFrom: string;
    labelTo: string;
    onlyWithTitle: string;
    resumes: string;
}

interface InputsGroupProps {
    values: NovaFilterRangeConvertedToStrings;
    onChange: (data: NovaFilterRangeConvertedToStrings, forceUpdate?: boolean) => void;
    updateFilters: () => void;
    name: typeof NovaFilterKey.Salary | typeof NovaFilterKey.Age;
    trls: NovaFilterRangeTrls;
}

const InputsGroup: FC<InputsGroupProps> = ({ values, onChange, updateFilters, name, trls }) => {
    const fromInputRef = useRef<HTMLInputElement>(null);
    const toInputRef = useRef<HTMLInputElement>(null);

    const handleChange = useCallback<InputProps['onChange']>(
        (value, scope) => {
            onChange({ ...values, [scope]: value });
        },
        [onChange, values]
    );

    return (
        <HSpacingContainer default={12}>
            <NovaFilterRangeInput
                name={name}
                value={`${values[Range.From] ?? ''}`}
                scope={Range.From}
                label={trls.labelFrom}
                onChange={handleChange}
                updateFilters={updateFilters}
                inputRef={fromInputRef}
            />
            <NovaFilterRangeInput
                name={name}
                value={`${values[Range.To] ?? ''}`}
                scope={Range.To}
                label={trls.labelTo}
                onChange={handleChange}
                updateFilters={updateFilters}
                inputRef={toInputRef}
            />
        </HSpacingContainer>
    );
};

export default InputsGroup;
