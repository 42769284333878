import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilter from 'lux/components/NovaFilters/components/NovaFilter';
import translation from 'lux/components/translation';
import { NovaFilterKey } from 'lux/models/novaFilters';

const TrlKeys = {
    title: 'clusters.job_search_status',
};

const JobSearchStatus: TranslatedComponent = ({ trls }) => {
    return <NovaFilter title={trls[TrlKeys.title]} name={NovaFilterKey.JobSearchStatus} />;
};

export default translation(JobSearchStatus);
