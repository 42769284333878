import { useMemo, FC } from 'react';

import { Divider, FormLabel, VSpacing } from '@hh.ru/magritte-ui';

import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'lux/components/NovaFilters/hooks/useSendFilterForm';
import NovaFilters, { NovaFilterKey, NovaListFilters } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

import NovaChipsSelect, { ChipsSelectType } from 'lux/components/NovaFilters/components/Magritte/NovaChipsSelect';

export interface NovaMobileFilterProps {
    name: NovaFilterKey;
    title: string;
    withDivider?: boolean;
}

const NovaMobileFilter: FC<NovaMobileFilterProps> = ({ name, title, withDivider = false }) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const { groups, selectedValues } = useSelector((state) => state.searchClusters?.[name as keyof NovaListFilters]);
    const groupList = useMemo(() => Object.values(groups), [groups]);

    return (
        <>
            <FormLabel>{title}</FormLabel>
            <VSpacing default={12} />
            <NovaChipsSelect
                name={name}
                selected={selectedValues?.map((item) => `${item}`) || []}
                options={groupList}
                selectType={ChipsSelectType.Checkbox}
                onChange={(values) => {
                    filterUpdate(values, name as keyof NovaFilters);
                    sendCountsRequest();
                }}
            />
            {withDivider ? <Divider marginTop={12} marginBottom={12} /> : <VSpacing default={24} />}
        </>
    );
};

export default NovaMobileFilter;
