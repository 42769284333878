import { useMemo, FC } from 'react';

import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'lux/components/NovaFilters/hooks/useSendFilterForm';
import NovaFilters, { NovaFilterKey, NovaListFilters } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

import NovaChipsSelect, { ChipsSelectType } from 'lux/components/NovaFilters/components/NovaChipsSelect';

export interface NovaXSFilterProps {
    name: NovaFilterKey;
    title: string;
    vBase?: 5 | 6;
}

const NovaXSFilter: FC<NovaXSFilterProps> = ({ name, title, vBase = 6 }) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const { groups, selectedValues } = useSelector((state) => state.searchClusters?.[name as keyof NovaListFilters]);
    const groupList = useMemo(() => Object.values(groups), [groups]);
    return (
        <div>
            <legend>
                <Text Element="span" size={TextSize.Large} strong>
                    {title}
                </Text>
            </legend>
            <VSpacing base={3} />
            <NovaChipsSelect
                name={name}
                selected={selectedValues?.map((item) => `${item}`) || []}
                options={groupList}
                selectType={ChipsSelectType.Multi}
                onChange={(values) => {
                    filterUpdate(values, name as keyof NovaFilters);
                    sendCountsRequest();
                }}
            />
            <VSpacing base={vBase} />
        </div>
    );
};

export default NovaXSFilter;
