import { useState, useCallback, useEffect, ReactElement, useRef } from 'react';

import { ChipsContainer, CustomChip, DataProvider, Input, VSpacing } from '@hh.ru/magritte-ui';

import { FormSuggestItem } from 'lux/components/Forms/formatter';
import SuggestWithDefaultErrorPlaceholder from 'lux/components/SuggestWithDefaultErrorPlaceholder';

import NovaFilterWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterWrapper';

export interface NovaFilterWithSuggestProps<T> {
    title: string;
    selectedValues: Array<T>;
    name: string;
    filterUpdate: (values: Array<T>) => void;
    dataProvider: DataProvider<T>;
    placeholder?: string;
    normalizeSelectedItem?: (item: T) => T;
}

const NovaFilterWithSuggest = <T extends FormSuggestItem>({
    title,
    selectedValues,
    name,
    filterUpdate,
    dataProvider,
    placeholder,
    normalizeSelectedItem,
}: NovaFilterWithSuggestProps<T>): ReactElement => {
    const suggestHostRef = useRef(null);
    const [selectedItems, setSelectedItems] = useState(selectedValues);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setSelectedItems(selectedValues);
    }, [selectedValues]);

    const handleSelect = useCallback(
        (_: string, item?: T) => {
            if (!item?.id) {
                return true;
            }
            const itemNormalized = normalizeSelectedItem?.(item) || item;
            if (!selectedItems.map(({ id }) => id).includes(itemNormalized.id)) {
                const newItems = [...selectedItems, itemNormalized];
                setTimeout(() => setInputValue(''));
                setSelectedItems(newItems);
                filterUpdate(newItems);
            }
            return true;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterUpdate, normalizeSelectedItem, selectedItems]
    );

    const handleRemove = useCallback(
        (itemId: string | number) => {
            const newItems = selectedItems.filter(({ id }) => id !== itemId);
            setSelectedItems(newItems);
            filterUpdate(newItems);
        },
        [filterUpdate, selectedItems]
    );

    return (
        <NovaFilterWrapper title={title}>
            <div style={{ position: 'relative' }} ref={suggestHostRef}>
                <SuggestWithDefaultErrorPlaceholder
                    navigationBarProps={{ title }}
                    dataProvider={dataProvider}
                    onSelectValidator={handleSelect}
                    inputValue={inputValue}
                    input={{
                        component: Input,
                        props: {
                            value: inputValue,
                            onChange: setInputValue,
                            placeholder,
                            'data-qa': `novafilter-item-input__${name}`,
                        },
                    }}
                />
            </div>
            <VSpacing default={12} />
            <ChipsContainer>
                {selectedItems.map(({ id, text }) => (
                    <CustomChip key={id} Element="button" onDelete={() => handleRemove(id)} aria-label={text}>
                        {text}
                    </CustomChip>
                ))}
            </ChipsContainer>
        </NovaFilterWrapper>
    );
};

export default NovaFilterWithSuggest;
