import { FC } from 'react';

import Checkbox from 'bloko/blocks/checkbox';

import FilterCount from 'lux/components/NovaFilters/components/FilterCount';
import FilterTitle from 'lux/components/NovaFilters/components/FilterTitle';
import ListItem from 'lux/components/NovaFilters/components/ListItem';
import { NovaFilterGroup } from 'lux/models/novaFilters';

interface NovaFiltersItemProps {
    item: NovaFilterGroup<string | number>;
    name: string;
    checked: boolean;
    onChange: (id: string) => void;
    truncated?: boolean;
    hideCount?: boolean;
}

const NovaFiltersItem: FC<NovaFiltersItemProps> = ({
    item: { title, count, id, ...prop },
    name,
    checked,
    onChange,
    truncated = false,
    hideCount = false,
}) => {
    const disabledItem = prop?.disabled;
    return (
        <ListItem>
            <Checkbox
                name={name}
                value={id}
                onChange={() => {
                    onChange(`${id}`);
                }}
                checked={checked}
                data-qa={`serp__novafilter-${name}-${id}`}
                labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                disabled={disabledItem}
            >
                <FilterTitle title={title} truncated={truncated} />
                {!disabledItem && !hideCount && <FilterCount count={count} />}
            </Checkbox>
        </ListItem>
    );
};

export default NovaFiltersItem;
