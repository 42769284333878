import { LanguageLevel } from '@hh.ru/types-hh-microcore';

import { Language } from 'lux/models/resumeLanguage';
import { useSelector } from 'lux/modules/useSelector';

const LANGUAGE_LEVEL_IDS = {
    a1: '1',
    a2: '2',
    b1: '3',
    b2: '4',
    c1: '5',
    c2: '6',
    l1: '7',
};

interface GetLanguagesProps {
    languages: Language[];
    record: string;
}

export const getLanguageLevel = ({ languages, record }: GetLanguagesProps): string | null => {
    if (record.length > 0) {
        const [code, degree] = record.split('.');
        const languageId = languages.find((lang) => lang.code === code)?.id.toString();
        const selectedLevel = degree as LanguageLevel;
        const levelId = LANGUAGE_LEVEL_IDS[selectedLevel];

        return languageId !== undefined && levelId ? `${languageId}.${levelId}` : null;
    }
    return null;
};

const useConvertLanguageLevel = (record: string): string | null => {
    const languages = useSelector(({ languages }) => languages);
    return getLanguageLevel({ languages: languages.language, record });
};

export default useConvertLanguageLevel;
