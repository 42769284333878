import { FC } from 'react';

import Text from 'bloko/blocks/text';

import { UniversityItem } from 'lux/models/search/advancedSearch';

const UniversitySuggestItem: FC<UniversityItem> = ({ text, acronym, synonyms }) => (
    <div className="primary-edu-suggestion">
        <abbr title={text} className="primary-edu-suggestion__acronym">
            <Text Element="span" strong>
                {acronym}
            </Text>
        </abbr>
        <div>
            <span className="suggest__text-hint">
                {synonyms}
                {!!synonyms && <br />}
                {text}
            </span>
        </div>
    </div>
);

export default UniversitySuggestItem;
