import { ReactNode, FC } from 'react';

import FilterTitle from 'lux/components/NovaFilters/components/FilterTitle';

type RenderPointIcon = (color: string, isMagritte?: boolean) => ReactNode;

export const renderPointIcon: RenderPointIcon = (color, isMagritte) => {
    if (isMagritte) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx={12} cy={12} r={5} fill={color} />
            </svg>
        );
    }
    return (
        <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="5.5" r="4" fill={color} />
        </svg>
    );
};

interface MetroPointProps {
    name: string;
    color: string;
}

const MetroPoint: FC<MetroPointProps> = ({ name, color }) => (
    <span>
        {color && <span className="novafilters-metro-icon">{renderPointIcon(color)}</span>}
        <FilterTitle title={name} truncated />
    </span>
);

export default MetroPoint;
