import { useState, useCallback, useEffect, useRef, KeyboardEvent } from 'react';

import { Radio as MagritteRadio, HSpacing, NumberInput } from '@hh.ru/magritte-ui';
import Tip, { TipTheme } from 'bloko/blocks/drop/Tip';
import InputText from 'bloko/blocks/inputText';
import Radio from 'bloko/blocks/radio';
import { KeyCode } from 'bloko/common/constants/keyboard';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberValidator, { NumberValidatorError } from 'bloko/common/numberValidator';

import FilterTitle from 'lux/components/NovaFilters/components/FilterTitle';
import ListItem from 'lux/components/NovaFilters/components/ListItem';
import MagritteListItem from 'lux/components/NovaFilters/components/Magritte/ListItem';
import MagritteNovaFilterItemWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import translation from 'lux/components/translation';
import { NovaFilterKey } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

import styles from './styles.less';

const TrlKeys = {
    labelFrom: 'employer.resumesSearch.salary.from',
    customSalary: 'vacancySearch.compensation.custom',
    maxExceeded: 'novafilters.maxExceeded',
    [NumberValidatorError.DecimalLength]: 'formatted.numeric.input.error.decimalLength',
    [NumberValidatorError.NotNumber]: 'formatted.numeric.input.error.notNumber',
};

interface Props {
    isInputEnabled: boolean;
    currentValueFrom: string;
    setCurrentValueFrom: (newValue: string) => void;
    setInputEnabled: (isEnabled: boolean) => void;
    updateFilters: (newValue?: string, withoutFormSending?: boolean) => void;
    isMagritte?: boolean;
}

const CustomCompensation: TranslatedComponent<Props> = ({
    trls,
    currentValueFrom,
    setCurrentValueFrom,
    isInputEnabled,
    setInputEnabled,
    updateFilters,
    isMagritte,
}) => {
    const needSendRequest = useRef(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const inputBlockRef = useRef<HTMLDivElement>(null);
    const showSearchPreference = useSelector((state) => state.searchPreference.isShown);
    const [inputError, setInputError] = useState<NumberValidatorError | null>(null);
    const onKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === KeyCode.Enter) {
            updateFilters();
            needSendRequest.current = false;
        }
    };

    const breakpoint = useBreakpoint();
    const isXsOrS = [Breakpoint.XS, Breakpoint.S].includes(breakpoint);

    useEffect(() => {
        if (isInputEnabled) {
            inputRef.current?.focus();
        }
    }, [isInputEnabled]);

    useEffect(() => {
        if (!inputBlockRef.current) {
            return;
        }
        // В Firefox задизейбленный инпут не кидает клик, и он не прокидывается выше. Из-за чего не срабатывал onClick
        // Добавляю хак с pointerEvents: none, чтобы клик приходил на div
        inputBlockRef.current.style.pointerEvents = 'none';
    }, []);

    const activateCustomCompensation = () => {
        if (!inputBlockRef.current) {
            return;
        }

        inputBlockRef.current.style.pointerEvents = 'auto';
        setInputEnabled(true);
    };

    const handleInputChange = useCallback(
        (value: string) => {
            const [error] = NumberValidator.validate(value, {
                groupSeparator: '',
                decimalLength: 0,
            });

            if (!error) {
                setCurrentValueFrom(value);
                updateFilters(value, true);
                setInputError(null);
            } else {
                setInputError(error);
            }
        },
        [setCurrentValueFrom, updateFilters]
    );

    if (isMagritte) {
        return (
            <>
                <MagritteNovaFilterItemWrapper
                    left={<MagritteRadio onChange={activateCustomCompensation} checked={isInputEnabled} />}
                    title={trls[TrlKeys.customSalary]}
                />
                <MagritteListItem>
                    <div className={styles.customSalary}>
                        <HSpacing default={36} />
                        <div className={styles.input} onClick={activateCustomCompensation}>
                            <div ref={inputBlockRef}>
                                <NumberInput
                                    name={NovaFilterKey.Salary}
                                    ref={inputRef}
                                    value={currentValueFrom}
                                    placeholder={trls[TrlKeys.labelFrom]}
                                    onChange={handleInputChange}
                                    data-qa="novafilters-custom-compensation"
                                    disabled={!isInputEnabled}
                                    onBlur={() => {
                                        if (needSendRequest.current) {
                                            updateFilters();
                                        } else {
                                            needSendRequest.current = true;
                                        }
                                    }}
                                    onKeyDown={onKeyDown}
                                />
                            </div>
                        </div>
                    </div>
                </MagritteListItem>
            </>
        );
    }

    return (
        <>
            <ListItem>
                <Radio
                    onChange={activateCustomCompensation}
                    checked={isInputEnabled}
                    labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                >
                    <FilterTitle title={trls[TrlKeys.customSalary]} />
                </Radio>
            </ListItem>
            <ListItem>
                <div className="novafilters-compensation" onClick={activateCustomCompensation}>
                    <Tip
                        host={!process.env.LUX_SERVER ? document.body : null}
                        show={!!inputError}
                        theme={TipTheme.Attention}
                        render={() => inputError && trls[TrlKeys[inputError]]}
                        onExternalClose={() => setInputError(null)}
                    >
                        <div ref={inputBlockRef}>
                            <InputText
                                name={NovaFilterKey.Salary}
                                ref={inputRef}
                                value={currentValueFrom}
                                prefix={showSearchPreference || !isXsOrS ? trls[TrlKeys.labelFrom] : ''}
                                onChange={handleInputChange}
                                data-qa="novafilters-custom-compensation"
                                disabled={!isInputEnabled}
                                onBlur={() => {
                                    if (needSendRequest.current) {
                                        updateFilters();
                                    } else {
                                        needSendRequest.current = true;
                                    }
                                }}
                                onKeyDown={onKeyDown}
                            />
                        </div>
                    </Tip>
                </div>
            </ListItem>
        </>
    );
};

export default translation(CustomCompensation);
