import { FormLabel, VSpacing as MagritteVSpacing, Radio as MagritteRadio } from '@hh.ru/magritte-ui';
import Radio from 'bloko/blocks/radio';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterCount from 'lux/components/NovaFilters/components/FilterCount';
import FilterTitle from 'lux/components/NovaFilters/components/FilterTitle';
import ListItem from 'lux/components/NovaFilters/components/ListItem';
import MagritteNovaChipsSelect, {
    ChipsSelectType as MagritteChipsSelectType,
} from 'lux/components/NovaFilters/components/Magritte/NovaChipsSelect';
import MagritteNovaControl from 'lux/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterItemWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import MagritteNovaFilterWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import NovaChipsSelect, { ChipsSelectType } from 'lux/components/NovaFilters/components/NovaChipsSelect';
import NovaControl from 'lux/components/NovaFilters/components/NovaControl';
import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'lux/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'lux/components/translation';
import { NovaFilterKey } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    doesNotMatter: 'vacancySearch.experience.doesNotMatter',
    title: 'searchvacancy.clusters.experience',
};

interface Props {
    name: typeof NovaFilterKey.Experience;
    isMagritte?: boolean;
}

const Experience: TranslatedComponent<Props> = ({ trls, isMagritte, name }) => {
    const sendCountsRequest = useDebouncedCountsRequest();
    const filterUpdate = useNovaFilterUpdate();
    const groups = useSelector((state) => state.searchClusters[NovaFilterKey.Experience].groups);
    const selectedValues = useSelector((state) => state.searchClusters[NovaFilterKey.Experience].selectedValues) || [];
    const onChangeExperience = (newValue: string[]) => {
        filterUpdate(newValue, NovaFilterKey.Experience);
    };

    if (isMagritte) {
        return (
            <MagritteNovaControl
                mobileView={
                    <>
                        <FormLabel>{trls[TrlKeys.title]}</FormLabel>
                        <MagritteVSpacing default={12} />
                        <MagritteNovaChipsSelect
                            name={name}
                            selected={selectedValues}
                            options={Object.values(groups)}
                            selectType={MagritteChipsSelectType.Radio}
                            onChange={(experience) => {
                                filterUpdate(experience, NovaFilterKey.Experience);
                                sendCountsRequest();
                            }}
                        />
                        <MagritteVSpacing default={24} />
                    </>
                }
            >
                <MagritteNovaFilterWrapper title={trls[TrlKeys.title]}>
                    <MagritteNovaFilterItemWrapper
                        left={
                            <MagritteRadio onChange={() => onChangeExperience([])} checked={!selectedValues.length} />
                        }
                        title={trls[TrlKeys.doesNotMatter]}
                    />
                    {Object.values(groups).map(({ id, title, count, disabled }) => (
                        <MagritteNovaFilterItemWrapper
                            key={id}
                            left={
                                <MagritteRadio
                                    value={id}
                                    onChange={() => onChangeExperience([id])}
                                    checked={selectedValues[0] === id}
                                />
                            }
                            title={title}
                            disabled={disabled}
                            count={count}
                        />
                    ))}
                </MagritteNovaFilterWrapper>
            </MagritteNovaControl>
        );
    }

    return (
        <NovaControl
            mobileView={
                <div>
                    <Text Element="span" size={TextSize.Large} strong>
                        {trls[TrlKeys.title]}
                    </Text>
                    <VSpacing base={3} />
                    <NovaChipsSelect
                        name={name}
                        selected={selectedValues}
                        options={Object.values(groups)}
                        selectType={ChipsSelectType.Single}
                        onChange={(experience) => {
                            filterUpdate(experience, NovaFilterKey.Experience);
                            sendCountsRequest();
                        }}
                    />
                    <VSpacing base={6} />
                </div>
            }
        >
            <NovaFilterWrapper title={trls[TrlKeys.title]}>
                <ListItem>
                    <Radio onChange={() => onChangeExperience([])} checked={!selectedValues.length}>
                        <FilterTitle title={trls[TrlKeys.doesNotMatter]} />
                    </Radio>
                </ListItem>
                {Object.values(groups).map(({ id, title, count, disabled }) => {
                    return (
                        <ListItem key={id}>
                            <Radio
                                value={id}
                                onChange={() => onChangeExperience([id])}
                                checked={selectedValues[0] === id}
                                disabled={disabled}
                            >
                                <FilterTitle title={title} />
                                {!disabled && <FilterCount count={count} />}
                            </Radio>
                        </ListItem>
                    );
                })}
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(Experience);
