import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { useSelectorNonNullable, makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';

import NovaFilters, { NovaFilterKey } from 'lux/models/novaFilters';
import { searchClustersUpdate } from 'lux/models/searchClusters';
import { useSelector } from 'lux/modules/useSelector';

import {
    useDebouncedCountsRequest,
    useDebouncedSendFilterFormByBreakpoint,
} from 'lux/components/NovaFilters/hooks/useSendFilterForm';

const searchChangedClustersAction = makeSetStoreField('searchClustersChanged');
const searchClustersLastAppliedFilterAction = makeSetStoreField('searchClustersLastAppliedFilter');
const searchLoadingAction = makeSetStoreField('searchLoading');

const FILTER_ANALYTICS_LABELS: Partial<Record<NovaFilterKey, string>> = {
    [NovaFilterKey.Compensation]: 'SALARY',
    [NovaFilterKey.Company]: 'EMPLOYER',
};

const getGenericAnalyticsValue = <F extends keyof NovaFilters>(value: NovaFilters[F]['selectedValues']) => {
    if (Array.isArray(value)) {
        return value.length ? value.join(', ') : 'null';
    }
    return value || 'null';
};

interface NovaFilterUpdate {
    <F extends keyof NovaFilters>(
        data: NovaFilters[F]['selectedValues'],
        filter: F,
        withoutFormSending?: boolean
    ): void;
}

export default (): NovaFilterUpdate => {
    const dispatch = useDispatch();
    const debouncedSendFilterFormByBreakpoint = useDebouncedSendFilterFormByBreakpoint();
    const debouncedCountsRequest = useDebouncedCountsRequest();

    const searchClusters = useSelectorNonNullable((state) => state.searchClusters);
    const searchType = useSelector((state) => state.searchClustersSettings.type);
    const defaultHhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const breakpoint = getBreakpoint();
    // tempexp_PORTFOLIO-30605_start
    const isIndexAnonSearchRedirectDisabled = useSelector((state) => state.isIndexAnonSearchRedirectDisabled);
    // tempexp_PORTFOLIO-30605_end

    return useCallback(
        (data, filter, withoutFormSending) => {
            const isXsOrS = [Breakpoint.XS, Breakpoint.S].includes(breakpoint);

            if (!withoutFormSending) {
                // Possible values of the buttonName:
                //   –  vacancy_search_filter_apply     /search/vacancy
                //   –  resume_search_filter_apply      /search/resume
                //   –  vacancy_search_filter_touch     /search/vacancy & XS | S filters
                //   –  resume_search_filter_touch      /search/resume  & XS | S filters
                const buttonName = `${searchType}_search_filter_${isXsOrS ? 'touch' : 'apply'}`;

                // Possible values of the hhtmSource:
                //   –  vacancy_search_list             /search/vacancy | /vacancies/
                //   –  resume_search_result            /search/resume
                //   –  vacancy_search_filter           /search/vacancy & XS | S filters
                //   –  resume_search_filter            /search/resume  & XS | S filters
                //   –  resumes_catalog                 /resumes/
                const hhtmSource = isXsOrS ? `${searchType}_search_filter` : defaultHhtmSource;

                Analytics.sendHHEventButtonClick(buttonName, {
                    type: FILTER_ANALYTICS_LABELS[filter] || filter.toUpperCase(),
                    oldValue: JSON.stringify(getGenericAnalyticsValue(searchClusters[filter]?.selectedValues)),
                    newValue: JSON.stringify(getGenericAnalyticsValue(data)),
                    hhtmSource,
                });
            }

            dispatch([
                searchClustersUpdate({ data, filter }),
                searchChangedClustersAction(true),
                searchClustersLastAppliedFilterAction({ filterName: filter }),
            ]);

            // tempexp_PORTFOLIO-30605_start
            // Апдейт фильтров на главной, но без редиректа в поиск
            if (isIndexAnonSearchRedirectDisabled) {
                debouncedSendFilterFormByBreakpoint({ filter });
                return;
            }
            // tempexp_PORTFOLIO-30605_end

            if (withoutFormSending) {
                return;
            }
            !isXsOrS && dispatch(searchLoadingAction(true));
            debouncedSendFilterFormByBreakpoint({ filter });
            debouncedCountsRequest();
        },
        [
            breakpoint,
            debouncedCountsRequest,
            debouncedSendFilterFormByBreakpoint,
            defaultHhtmSource,
            dispatch,
            searchClusters,
            searchType,
            isIndexAnonSearchRedirectDisabled,
        ]
    );
};
