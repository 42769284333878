import { FormItem } from 'bloko/blocks/form';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    remove: 'vacancyresponse.filters.language.remove',
};

interface RemoveLinkProps {
    onClick: () => void;
    dataQa: string;
}

const RemoveLink: TranslatedComponent<RemoveLinkProps> = ({ trls, onClick, dataQa }) => (
    <>
        <FormItem>
            <Link appearance={LinkAppearance.Pseudo} onClick={onClick} data-qa={dataQa}>
                {trls[TrlKeys.remove]}
            </Link>
        </FormItem>
        <VSpacing base={3} />
    </>
);

export default translation(RemoveLink);
