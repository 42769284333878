import { Link, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

interface LinkMoreProps {
    length?: number;
    expanded?: boolean;
    onClick?: () => void;
}

const TrlKeys = {
    showMore: 'clusters.showMore',
    hide: 'novafilters.area.hide',
};

const LinkMore: TranslatedComponent<LinkMoreProps> = ({ trls, length, onClick, expanded, children }) => (
    <>
        <VSpacing default={12} />
        <div onClick={onClick} data-qa={expanded ? 'serp__novafilter-item-hide' : 'serp__novafilter-item-more'}>
            {children || <Link>{expanded ? trls[TrlKeys.hide] : `${trls[TrlKeys.showMore]} ${length || ''}`}</Link>}
        </div>
    </>
);

export default translation(LinkMore);
