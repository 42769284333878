import { useMemo } from 'react';

import { NovaFilterGroup, NovaFilterGroupMap } from 'lux/models/novaFilters';

export const HIDE_FILTERS_AFTER = 5;

type SortFunc = (a: NovaFilterGroup<string>, b: NovaFilterGroup<string>) => number;

interface FilterGroupResult<T extends string = string> {
    items: NovaFilterGroup<T>[];
    breakpoint: number;
}

export const defaultGroupsSort: SortFunc = (a, b) => b.count - a.count;
export const sortByOrders = (items: NovaFilterGroup<string>[], orders: string[]): void => {
    items.sort((a, b) => {
        const sortA = orders.indexOf(a.id);
        const sortB = orders.indexOf(b.id);
        if (sortA === -1 && sortB === -1) {
            return 0;
        }
        if (sortA === -1 && sortB !== -1) {
            return 1;
        }
        if (sortA !== -1 && sortB === -1) {
            return -1;
        }
        return sortA - sortB;
    });
};

const useNovaFiltersGroups = <T extends string = string>(
    groups: NovaFilterGroupMap<T>,
    length: number,
    orders: string[],
    expanded = false,
    sortFunc: SortFunc = defaultGroupsSort
): FilterGroupResult<T> =>
    useMemo(() => {
        const items: NovaFilterGroup<T>[] = Object.values(groups);
        // sort by count or use other func
        items.sort(sortFunc);
        if (orders && !expanded) {
            sortByOrders(items, orders);
        }
        const breakpoint = Math.max(HIDE_FILTERS_AFTER, length);
        return {
            items,
            breakpoint: items.length - breakpoint === 1 ? items.length : breakpoint,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded, groups, length, orders, sortFunc]);

export default useNovaFiltersGroups;
