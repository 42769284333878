import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'lux/models/currencies.types';
import { NovaFilterKey } from 'lux/models/novaFilters';

import { useDebouncedSendFilterFormByBreakpoint } from 'lux/components/NovaFilters/hooks/useSendFilterForm';

/**
 * Экшен для изменения валюты дохода в фильтрах.
 *
 * @param currency Код выбранной валюты.
 * @param [filter] Код измененного кластера фильтров.
 */
type UpdateCurrencyAction = (currency: CurrencyType, filter?: NovaFilterKey) => void;

const criteriaCurrencyCodeAction = makeSetStoreField('criteriaCurrencyCode');
const searchLoadingAction = makeSetStoreField('searchLoading');

/**
 * Хук создания экшена для изменения валюты дохода в фильтрах.
 *
 * @returns UpdateCurrencyAction;
 */
export default (): UpdateCurrencyAction => {
    const dispatch = useDispatch();
    const debouncedSendFilterFormByBreakpoint = useDebouncedSendFilterFormByBreakpoint();

    return useCallback(
        (currency, filter) => {
            dispatch([criteriaCurrencyCodeAction(currency), searchLoadingAction(true)]);
            debouncedSendFilterFormByBreakpoint({ filter });
        },
        [debouncedSendFilterFormByBreakpoint, dispatch]
    );
};
