import { forwardRef, PropsWithChildren } from 'react';

import useMagritte from 'lux/hooks/useMagritte';

import FilterListBloko from 'lux/components/NovaFilters/components/Bloko/FilterList';
import FilterListMagritte from 'lux/components/NovaFilters/components/Magritte/FilterList';

interface FilterListProps {
    expanded?: boolean;
    len?: number;
    scrollable?: boolean;
}

const FilterList = forwardRef<HTMLUListElement, PropsWithChildren<FilterListProps>>((props, ref) => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? FilterListMagritte : FilterListBloko;

    return <Component ref={ref} {...props} />;
});

export default FilterList;
