import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilterContent from 'lux/components/NovaFilters/components/NovaFilterContent';
import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';
import translation from 'lux/components/translation';
import { NovaFilterGroup, NovaFilterKey } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

const districtSort = (a: NovaFilterGroup<string>, b: NovaFilterGroup<string>) => {
    if (a.title > b.title) {
        return 1;
    }
    if (b.title > a.title) {
        return -1;
    }
    return 0;
};

const TrlKeys = {
    title: 'clusters.district',
};

const District: TranslatedComponent = ({ trls }) => {
    const itemsLen = useSelector((state) => {
        const cluster = state.searchClusters?.[NovaFilterKey.District];
        return cluster ? Object.values(cluster.groups).length : 0;
    });
    if (itemsLen === 0) {
        return null;
    }
    return (
        <NovaFilterWrapper title={trls[TrlKeys.title]}>
            <NovaFilterContent name={NovaFilterKey.District} sortFunc={districtSort} />
        </NovaFilterWrapper>
    );
};

export default translation(District);
