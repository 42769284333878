import { FC, RefObject, useCallback, KeyboardEventHandler } from 'react';

import { NumberInput } from '@hh.ru/magritte-ui';

import { NovaFilterKey, Range } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

export interface InputProps {
    name: typeof NovaFilterKey.Salary | typeof NovaFilterKey.Age;
    value: string;
    scope: Range;
    label: string;
    onChange: (value: string, scope: Range) => void;
    updateFilters: () => void;
    inputRef?: RefObject<HTMLInputElement>;
}

const Input: FC<InputProps> = ({ name, value, scope, label, onChange, updateFilters, inputRef }) => {
    const showSearchPreference = useSelector((state) => state.searchPreference.isShown);

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                updateFilters();
            }
        },
        [updateFilters]
    );

    const key = scope ? `${name}_${scope}` : name;

    return (
        <NumberInput
            name={name}
            value={value}
            placeholder={label}
            ref={inputRef}
            onBlur={() => updateFilters()}
            onChange={(value) => onChange(value, scope)}
            onKeyDown={onKeyDown}
            key={`novafilters-chart-input-${key}-${String(showSearchPreference)}`}
            data-qa={`novafilters-${key}`}
        />
    );
};

export default Input;
