import { Language } from 'lux/models/resumeLanguage';

export const DEFAULT_LANGUAGES = ['eng', 'fra', 'deu'];

const sortForLanguages = (languages: Language[]): Language[] => {
    const defaultLanguages = languages.filter((language) => DEFAULT_LANGUAGES.includes(language.code));
    const otherLanguages = languages.filter((language) => !DEFAULT_LANGUAGES.includes(language.code));
    return [...defaultLanguages, ...otherLanguages];
};

export default sortForLanguages;
