import { Link as MagritteLink, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import SearchType from 'lux/components/NovaFilters/SearchType';
import MagritteNovaControl from 'lux/components/NovaFilters/components/Magritte/NovaControl';
import NovaControl from 'lux/components/NovaFilters/components/NovaControl';
import useResetLink from 'lux/components/NovaFilters/hooks/useResetLink';

const TrlKeys = {
    reset: 'novafilters.reset',
};

interface ResetProps {
    isMagritte?: boolean;
}

const Reset: TranslatedComponent<ResetProps> = ({ isMagritte, trls }) => {
    const isResumeSearch = useSelector((state) => state.searchClustersSettings.type) === SearchType.Resume;

    const totalUsedVacancyFilters = useSelector((state) => state.vacancySearchResult.totalUsedFilters);
    const totalUsedResumeFilters = useSelector((state) => state.resumeSearchResult.totalUsedFilters);
    const totalUsedFilters = totalUsedVacancyFilters || totalUsedResumeFilters || 0;

    const resetLink = useResetLink();

    if (isMagritte) {
        return (
            <MagritteNovaControl>
                {isResumeSearch && <VSpacing default={24} />}
                <MagritteLink
                    style="neutral"
                    typography="label-2-regular"
                    Element={Link}
                    to={resetLink}
                    disabled={!totalUsedFilters}
                >
                    {trls[TrlKeys.reset]}
                </MagritteLink>
                <VSpacing default={24} />
            </MagritteNovaControl>
        );
    }

    return (
        <NovaControl>
            <div className="novafilters-group-wrapper">
                <BlokoLink Element={Link} kind={LinkKind.Secondary} to={resetLink}>
                    {trls[TrlKeys.reset]}
                </BlokoLink>
            </div>
        </NovaControl>
    );
};

export default translation(Reset);
