import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import RegionSuggest from 'lux/components/NovaFilters/RegionSuggest';
import translation from 'lux/components/translation';
import { NovaFilterKey } from 'lux/models/novaFilters';

const TrlKeys = {
    title: 'employer.resumesSearch.workTicket',
};

interface Props {
    active: boolean;
}

const WorkTicket: TranslatedComponent<Props> = ({ trls, active }) => {
    if (!active) {
        return <>{trls[TrlKeys.title]}</>;
    }
    return <RegionSuggest title={trls[TrlKeys.title]} name={NovaFilterKey.WorkTicket} />;
};

export default translation(WorkTicket);
