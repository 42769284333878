import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaControl from 'lux/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaSearchOrder from 'lux/components/NovaFilters/components/Magritte/SortingGroup/NovaSearchOrder';
import MagritteNovaSearchPeriod from 'lux/components/NovaFilters/components/Magritte/SortingGroup/NovaSearchPeriod';
import NovaControl from 'lux/components/NovaFilters/components/NovaControl';
import translation from 'lux/components/translation';

import NovaSearchOrder from 'lux/components/NovaFilters/components/SortingGroup/NovaSearchOrder';
import NovaSearchPeriod from 'lux/components/NovaFilters/components/SortingGroup/NovaSearchPeriod';

const SortingGroup: TranslatedComponent<{ isMagritte?: boolean }> = ({ isMagritte }) => {
    if (isMagritte) {
        return (
            <MagritteNovaControl
                mobileView={
                    <>
                        <MagritteNovaSearchOrder />
                        <MagritteNovaSearchPeriod />
                    </>
                }
            />
        );
    }

    return (
        <NovaControl
            mobileView={
                <>
                    <NovaSearchOrder />
                    <NovaSearchPeriod />
                </>
            }
        />
    );
};

export default translation(SortingGroup);
