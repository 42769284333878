import React, { useMemo, useState } from 'react';

import { Input, VSpacing, Select, SelectOption, useBreakpoint } from '@hh.ru/magritte-ui';
import { BarsOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import CompositeSelection from 'bloko/blocks/compositeSelection';
import CustomSelect, { CustomSelectOption } from 'bloko/blocks/customSelect';
import { BarsScaleSmall, IconColor } from 'bloko/blocks/icon';
import InputText from 'bloko/blocks/inputText';
import getConversionType from 'bloko/common/getConversionType';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';
import { format } from 'bloko/common/trl';

import FilterTitle from 'lux/components/NovaFilters/components/FilterTitle';
import MobileTreeSelector from 'lux/components/NovaFilters/components/Magritte/MobileTreeSelector';
import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import translation from 'lux/components/translation';
import useMagritte from 'lux/hooks/useMagritte';
import { NovaFilterKey } from 'lux/models/novaFilters';
import { CriteriaKey, FilterExpPeriodOption } from 'lux/models/search/searchCriteria.types';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    title: 'employer.resumesSearch.expIndustryWithPeriod.title',
    select: {
        one: 'employer.resumesSearch.expIndustry.select.one',
        some: 'employer.resumesSearch.expIndustry.select.some',
        many: 'employer.resumesSearch.expIndustry.select.many',
        all: 'employer.resumesSearch.expIndustry.select.all',
    },
    modal: {
        title: 'employer.resumesSearch.expIndustry.modal.title',
        save: 'treeselector.save',
        cancel: 'treeselector.cancel',
        placeholder: 'treeselector.quicksearch',
        notFound: 'treeselector.notFound',
    },
};

const immutableInputChange = () => undefined;

const ExpIndustryWithPeriod: TranslatedComponent = ({ trls }) => {
    const isMagritte = useMagritte();

    const [showModal, setShowModal] = useState<boolean>(false);
    const filterUpdate = useNovaFilterUpdate();

    const selectedExpIndustries =
        useSelector((state) => state.searchClusters?.[NovaFilterKey.FilterExpIndustry]?.selectedValues) || [];
    const { selectedValues: selectedExpPeriod, groups } = useSelector(
        (state) => state.searchClusters?.[NovaFilterKey.FilterExpPeriodForIndustry]
    );

    const industryTree = useSelector((state) => state.industriesTree);
    const industryTreeTreeCollection = useMemo(() => fromTree(industryTree), [industryTree]);

    const selectedValuesTrl = useMemo(() => {
        if (selectedExpIndustries.length === 0) {
            return trls[TrlKeys.select.all];
        }
        const conversionTrl = getConversionType({
            one: trls[TrlKeys.select.one],
            some: trls[TrlKeys.select.some],
            many: trls[TrlKeys.select.many],
            value: selectedExpIndustries.length,
        });
        return format(conversionTrl, { '{0}': selectedExpIndustries.length });
    }, [trls, selectedExpIndustries]);

    const selectOptions: SelectOption<FilterExpPeriodOption>[] = useMemo(
        () => Object.values(groups).map((item) => ({ value: item.id, label: item.title })),
        [groups]
    );

    const { isMobile } = useBreakpoint();

    return (
        <NovaFilterWrapper title={trls[TrlKeys.title]}>
            {(!isMagritte || (isMagritte && !isMobile)) && (
                <CompositeSelection
                    collection={industryTreeTreeCollection}
                    value={selectedExpIndustries}
                    onChange={(values) => {
                        filterUpdate(values, NovaFilterKey.FilterExpIndustry);
                    }}
                    title={trls[TrlKeys.modal.title]}
                    trl={{
                        submit: trls[TrlKeys.modal.save],
                        cancel: trls[TrlKeys.modal.cancel],
                        searchPlaceholder: trls[TrlKeys.modal.placeholder],
                        notFound: trls[TrlKeys.modal.notFound],
                    }}
                >
                    {({ showTreeSelectorPopup }) =>
                        isMagritte ? (
                            <Input
                                buttonIcon={BarsOutlinedSize24}
                                onButtonClick={showTreeSelectorPopup}
                                onFocus={showTreeSelectorPopup}
                                value={selectedValuesTrl}
                                onChange={immutableInputChange}
                                data-qa="serp__novafilter-exp-industry-select"
                            />
                        ) : (
                            <InputText
                                onClick={showTreeSelectorPopup}
                                value={selectedValuesTrl}
                                icon={<BarsScaleSmall initial={IconColor.Gray50} highlighted={IconColor.Gray60} />}
                                data-qa="serp__novafilter-exp-industry-select"
                            />
                        )
                    }
                </CompositeSelection>
            )}
            {isMagritte && isMobile && (
                <>
                    <Input
                        buttonIcon={BarsOutlinedSize24}
                        onButtonClick={() => setShowModal(true)}
                        onFocus={() => setShowModal(true)}
                        value={selectedValuesTrl}
                        onChange={immutableInputChange}
                        data-qa="serp__novafilter-exp-industry-select"
                    />
                    <MobileTreeSelector
                        name={NovaFilterKey.FilterExpIndustry}
                        collection={industryTreeTreeCollection}
                        initialValues={selectedExpIndustries}
                        showModal={showModal}
                        setShowModal={setShowModal}
                    />
                </>
            )}
            <VSpacing default={12} />
            {!isMagritte && groups && (
                <CustomSelect
                    name={CriteriaKey.FilterExpPeriodForIndustry}
                    value={selectedExpPeriod}
                    onChange={(newValue) => {
                        filterUpdate(newValue, NovaFilterKey.FilterExpPeriodForIndustry);
                    }}
                    data-qa="serp__novafilter-exp-period-select"
                >
                    {Object.values(groups).map((item) => (
                        <CustomSelectOption
                            value={item.id}
                            key={item.id}
                            data-qa={`serp__novafilter-exp-period-${item.id}`}
                        >
                            <FilterTitle title={item.title} />
                        </CustomSelectOption>
                    ))}
                </CustomSelect>
            )}
            {isMagritte && groups && (
                <Select
                    name={CriteriaKey.FilterExpPeriodForIndustry}
                    placeholder={trls[TrlKeys.title]}
                    options={selectOptions}
                    value={selectedExpPeriod}
                    onChange={(newValue) =>
                        filterUpdate(newValue as FilterExpPeriodOption, NovaFilterKey.FilterExpPeriodForIndustry)
                    }
                    bottomSheetHeight="content"
                />
            )}
        </NovaFilterWrapper>
    );
};

export default translation(ExpIndustryWithPeriod);
