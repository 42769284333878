import { FC } from 'react';
import classnames from 'classnames';

import Checkbox from 'bloko/blocks/checkbox';

import FilterCount from 'lux/components/NovaFilters/components/FilterCount';
import FilterTitle from 'lux/components/NovaFilters/components/FilterTitle';
import ListItem from 'lux/components/NovaFilters/components/ListItem';
import { MetroType, NovaFilterMetroGroup } from 'lux/models/novaFilters';

import MetroLine, { renderLineIcon } from 'lux/components/NovaFilters/vacancies/Metro/MetroLine';
import MetroPoint, { renderPointIcon } from 'lux/components/NovaFilters/vacancies/Metro/MetroPoint';

import styles from './metroCheckbox.less';

interface MetroCheckboxProps extends NovaFilterMetroGroup {
    name: string;
    onChange: (value: string, metro: NovaFilterMetroGroup) => void;
    checked: boolean;
    indeterminate: boolean;
    hideCount?: boolean;
    disabled?: boolean;
    xsView?: boolean;
}

const MetroCheckbox: FC<MetroCheckboxProps> = ({
    checked,
    name,
    indeterminate,
    onChange,
    disabled = false,
    hideCount = false,
    xsView = false,
    ...metro
}) => {
    const { title, id, count, color, type } = metro;
    return (
        <ListItem>
            <Checkbox
                value={id}
                name={name}
                onChange={({ target }) => {
                    onChange(target.value, metro);
                }}
                checked={checked}
                data-qa={`serp__novafilter-metro-${id}`}
                labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                indeterminate={indeterminate}
                disabled={disabled}
            >
                <span className={classnames({ [styles.metroNotXs]: xsView })}>
                    {type === MetroType.Station && <MetroPoint color={color} name={title} />}
                    {type === MetroType.Line && <MetroLine color={color} name={title} />}
                </span>
                {xsView && (
                    <span className={styles.metroTitleXs}>
                        <FilterTitle title={title} truncated />
                    </span>
                )}
                {!hideCount && !disabled && <FilterCount count={count} />}
            </Checkbox>
            {xsView && (
                <span className={styles.metroTypeXs}>
                    {type === MetroType.Station && renderPointIcon(color)}
                    {type === MetroType.Line && renderLineIcon(color)}
                </span>
            )}
        </ListItem>
    );
};

export default MetroCheckbox;
