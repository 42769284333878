import { FC } from 'react';

import { Checkbox as MagritteCheckbox } from '@hh.ru/magritte-ui';
import Checkbox from 'bloko/blocks/checkbox';
import VSpacing from 'bloko/blocks/vSpacing';

import FilterCount from 'lux/components/NovaFilters/components/FilterCount';
import FilterTitle from 'lux/components/NovaFilters/components/FilterTitle';
import ListItem from 'lux/components/NovaFilters/components/ListItem';
import MagritteNovaControl from 'lux/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterItemWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import MagritteNovaFilterWrapper from 'lux/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import MagritteNovaSwitchItem from 'lux/components/NovaFilters/components/Magritte/NovaSwitchItem';
import NovaControl from 'lux/components/NovaFilters/components/NovaControl';
import NovaFilterWrapper from 'lux/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'lux/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'lux/components/NovaFilters/hooks/useSendFilterForm';
import { NovaFilterKey } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

const AcceptTemporary: FC<{ isMagritte?: boolean }> = ({ isMagritte }) => {
    // callbacks
    const sendCountsRequest = useDebouncedCountsRequest();
    const filterUpdate = useNovaFilterUpdate();
    const onChangeAcceptTemporary = (newValue: boolean) => {
        filterUpdate(newValue, NovaFilterKey.AcceptTemporary);
    };

    // single filter group
    const name = NovaFilterKey.AcceptTemporary;
    const groups = useSelector((state) => state.searchClusters[NovaFilterKey.AcceptTemporary]?.groups);
    const items = Object.values(groups ?? {});
    const group = items.length > 0 ? items.pop() : false;
    const value = !!useSelector((state) => state.searchClusters[NovaFilterKey.AcceptTemporary]?.selectedValues);

    // can be empty
    if (!group) {
        return null;
    }

    if (isMagritte) {
        return (
            <MagritteNovaControl
                mobileView={
                    <MagritteNovaSwitchItem
                        title={group.title}
                        checked={value}
                        onClick={() => {
                            onChangeAcceptTemporary(!value);
                            sendCountsRequest();
                        }}
                        dataQa={`serp__novafilter-${name}-${group.id}`}
                    />
                }
            >
                <MagritteNovaFilterWrapper>
                    <MagritteNovaFilterItemWrapper
                        left={
                            <MagritteCheckbox
                                name={name}
                                value={group.id}
                                dataQaCheckbox={`serp__novafilter-${name}-${group.id}`}
                                onChange={() => onChangeAcceptTemporary(!value)}
                                checked={value}
                            />
                        }
                        title={group.title}
                        disabled={group.disabled}
                        count={group.count}
                    />
                </MagritteNovaFilterWrapper>
            </MagritteNovaControl>
        );
    }

    return (
        <NovaControl
            mobileView={
                <div>
                    <ListItem>
                        <Checkbox
                            name={name}
                            value={group.id}
                            data-qa={`serp__novafilter-${name}-${group.id}`}
                            onChange={() => {
                                onChangeAcceptTemporary(!value);
                                sendCountsRequest();
                            }}
                            checked={value}
                            disabled={group.disabled}
                        >
                            <FilterTitle title={group.title} />
                        </Checkbox>
                    </ListItem>
                    <VSpacing base={6} />
                </div>
            }
        >
            <NovaFilterWrapper>
                <ListItem>
                    <Checkbox
                        name={name}
                        value={group.id}
                        data-qa={`serp__novafilter-${name}-${group.id}`}
                        onChange={() => onChangeAcceptTemporary(!value)}
                        checked={value}
                        disabled={group.disabled}
                    >
                        <FilterTitle title={group.title} />
                        {!group.disabled && <FilterCount count={group.count} />}
                    </Checkbox>
                </ListItem>
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default AcceptTemporary;
