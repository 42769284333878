import { FC } from 'react';

import useMagritte from 'lux/hooks/useMagritte';
import { NovaFilterGroup, NovaFilterKey } from 'lux/models/novaFilters';

import NovaFilterContentBloko from 'lux/components/NovaFilters/components/Bloko/NovaFilterContent';
import NovaFilterContentMagritte from 'lux/components/NovaFilters/components/Magritte/NovaFilterContent';

export interface FilterContent {
    name: NovaFilterKey;
    truncated?: boolean;
    sortFunc?: (a: NovaFilterGroup<string>, b: NovaFilterGroup<string>) => number;
    shouldSendCountsRequest?: boolean;
}

const NovaFilterContent: FC<FilterContent> = (props) => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? NovaFilterContentMagritte : NovaFilterContentBloko;

    return <Component {...props} />;
};

export default NovaFilterContent;
